import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import imgSrc from '../Image/error-404.png';
import MainLayout from '../layout/MainLayout';
import { usePageTitleStore } from '../store/PageTitleStore';

function NotFoundPage() {
  const { updateTitle } = usePageTitleStore();

  useEffect(() => {
    updateTitle('404 not found');
  }, []);

  return (
    <MainLayout>
      <Box
        component='main'
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%',
        }}
      >
        <Container maxWidth='md'>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                mb: 3,
                textAlign: 'center',
              }}
            >
              <img
                alt='Under development'
                src={imgSrc}
                style={{
                  display: 'inline-block',
                  maxWidth: '100%',
                  width: 400,
                }}
              />
            </Box>
            <Typography
              align='center'
              sx={{ mb: 3 }}
              variant='h3'
              fontWeight={700}
            >
              404: The page you are looking for isn’t here
            </Typography>
            <Typography align='center' color='text.secondary' variant='body1'>
              You either tried some shady route or you came here by mistake.
              Whichever it is, try using the navigation
            </Typography>
            <Stack direction='row' spacing={3} sx={{ my: 3 }}>
              <Button
                href='/'
                startIcon={
                  <SvgIcon fontSize='small'>
                    <ArrowLeftIcon />
                  </SvgIcon>
                }
                disableElevation
                variant='contained'
              >
                Home
              </Button>
              <Button
                href='/'
                endIcon={
                  <SvgIcon fontSize='small'>
                    <QuizOutlinedIcon />
                  </SvgIcon>
                }
                variant='outlined'
              >
                Help
              </Button>
            </Stack>
          </Box>
        </Container>
      </Box>
    </MainLayout>
  );
}

export default NotFoundPage;
