import {
  Box,
  Button,
  ButtonGroup,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';
import { getProjectEstimate } from '../../services/CustomerService';

const researchArray = [
  {
    title: 'How solar works?',
    description:
      'Using sunlight to create DC power from silicon wafers, solar energy is the cheapest form of energy source available!',
    action: 'Explore how solar works?',
    link: 'https://www.vaysolar.com/docs/research/how-it-works/',
  },
  {
    title: 'Cost of solar',
    description:
      'Solar panel prices have reduced significantly in the last 5 years. There are government subsidies available to consumers as well.',
    action: 'Read more',
    link: 'https://www.vaysolar.com/docs/research/cost/',
  },
  {
    title: 'Benefits of Solar',
    description:
      "Solar plants on your rooftop can reduce your electricity bills by upto 90%. They also increase your property's value in the market!",
    action: 'Estimate benefits',
    link: 'https://www.vaysolar.com/docs/research/benefits/',
  },
];

const InstallationTimelineV2 = () => {
  const {
    useFormInputs,
    activeStep,
    updateFormInputs,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const [activeTab, setActiveTab] = useState(0);
  const { updateTitle } = usePageTitleStore();
  const [numberOfSolarInstallers, setNumberOfSolarInstallers] = useState(null);
  const [region, setRegion] = useState('');
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required(t('main.error.selectOption')),
  });

  const formik = useFormik({
    initialValues: {
      selectedOption: useFormInputs.propertyDetails.installationTimeline,
    },
    validationSchema,
    onSubmit: (values) => {
      updateFormInputs({
        ...useFormInputs,
        propertyDetails: {
          ...useFormInputs['propertyDetails'],
          installationTimeline: values.selectedOption.toString(),
        },
      });
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    },
    enableReinitialize: true,
  });

  const options = [
    {
      name: t('main.installationTimeline.options.opt1'),
      value: '1 month',
    },
    {
      name: t('main.installationTimeline.options.opt2'),
      value: '1-3 months',
    },
    {
      name: t('main.installationTimeline.options.opt3'),
      value: '3+ months',
    },
  ];

  const handleClick = () => {
    window.open('https://www.vaysolar.com/docs/', '_blank');
  };

  useEffect(() => {
    updateTitle('Installation Timeline | VaySolar');
    setSubmitIsClicked(false);
  }, [setSubmitIsClicked, updateTitle, useFormInputs]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      setSubmitIsClicked(false);
    }
  }, [submitIsClicked, formik, setSubmitIsClicked]);

  useEffect(() => {
    const onMount = async () => {
      try {
        const region = useFormInputs.propertyDetails.address?.state || 'Delhi';
        const sanctionedLoad =
          useFormInputs.propertyDetails.sanctionedLoad || 3;
        const propertyType =
          useFormInputs.propertyDetails.propertyType || 'RESIDENTIAL';
        const billAmount = useFormInputs.propertyDetails.billAmount || 2000;
        const billMonth = useFormInputs.propertyDetails.billMonth || 'JANUARY';
        const shadowFreeArea =
          useFormInputs.propertyDetails.shadowFreeArea || 0;
        const data = await getProjectEstimate(
          region,
          propertyType,
          sanctionedLoad,
          billAmount,
          shadowFreeArea,
          billMonth,
        );
        if (data.length === 0) throw new Error('No data found');
        const transformedData = data.output;
        setNumberOfSolarInstallers(transformedData.numberOfSolarInstallers);
        setRegion(transformedData.region);
        updateFormInputs({
          ...useFormInputs,
          siteVisitData: {
            ...useFormInputs.siteVisitData,
            numberOfSolarInstallers: transformedData.numberOfSolarInstallers,
            region: transformedData.region,
          },
        });
      } catch (error) {
        console.log(error);
      }
    };
    onMount();
  }, []);

  return (
    <Container sx={{ marginLeft: '0px !important' }}>
      <Box overflow='auto'>
        <Stack alignItems={'center'}>
          <Stack
            alignItems={isMobile ? 'flex-start' : 'center'}
            width='100%'
            maxWidth={'600px'}
          >
            {numberOfSolarInstallers && region && (
              <Typography
                sx={{
                  fontSize: {
                    xs: theme.typography.h5.fontSize,
                    md: theme.typography.h4.fontSize,
                  },
                  fontWeight: 'bold',
                }}
              >
                <span style={{ color: theme.palette.primary.main }}>
                  {numberOfSolarInstallers}
                  {t('main.installationTimeline.solarInstallers')}
                </span>{' '}
                {region}
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: {
                  xs: theme.typography.subtitle1.fontSize,
                  md: theme.typography.subtitle1.fontSize,
                },
                marginBottom: '20px',
              }}
            >
              {t('main.installationTimeline.title')}
            </Typography>
          </Stack>
          <ButtonGroup
            sx={{
              width: '100%',
              maxWidth: '600px',
              '& .MuiButton-root': {
                flexGrow: 1,
              },
              my: 2,
              mb: 3,
            }}
          >
            <Button
              variant={activeTab === 0 ? 'contained' : 'outlined'}
              onClick={() => setActiveTab(0)}
              className={activeTab === 0 ? 'Mui-selected' : ''}
              selected={activeTab === 0}
            >
              {t('main.installationTimeline.tab1')}
            </Button>
            <Button
              variant={activeTab === 1 ? 'contained' : 'outlined'}
              onClick={() => setActiveTab(1)}
              className={activeTab === 1 ? 'Mui-selected' : ''}
              selected={activeTab === 1}
            >
              {t('main.installationTimeline.tab2')}
            </Button>
          </ButtonGroup>
          {activeTab === 0 && (
            <Stack
              alignItems={isMobile ? 'flex-start' : 'center'}
              width='100%'
              maxWidth={'600px'}
            >
              <Stack spacing={1} width='100%' maxWidth={'600px'}>
                {options.map((option, index) => (
                  <RadioGroup
                    key={index}
                    value={formik.values.selectedOption ?? ''}
                    onChange={(event) => {
                      formik.setFieldValue(
                        'selectedOption',
                        event.target.value,
                      );
                      formik.setFieldTouched('selectedOption');
                      setTimeout(formik.handleSubmit, 0);
                    }}
                  >
                    <FormControlLabel
                      value={option.value}
                      control={<Radio />}
                      label={<Typography>{option.name}</Typography>}
                    />
                  </RadioGroup>
                ))}
              </Stack>
              {formik.touched.selectedOption && formik.errors.selectedOption ? (
                <Typography sx={{ color: theme.palette.error.main }}>
                  {formik.errors.selectedOption}
                </Typography>
              ) : null}
            </Stack>
          )}
          {activeTab === 1 && (
            <Stack
              direction={'row'}
              px={1}
              pb={6}
              justifyContent={'center'}
              rowGap={1.5}
              columnGap={1.5}
              alignItems={'stretch'}
              flexWrap={{
                md: 'nowrap',
                xs: 'wrap',
              }}
            >
              {researchArray.map((item, index) => (
                <Card
                  sx={{
                    boxShadow: 'none',
                    border: '1px solid lightGray',
                    borderRadius: '8px',
                  }}
                  key={index}
                >
                  <CardHeader
                    title={
                      <Stack direction={'row'}>
                        <Typography
                          variant='h6'
                          component='div'
                          fontWeight={'bold'}
                        >
                          {item.title}
                        </Typography>
                      </Stack>
                    }
                  />
                  <CardContent>
                    <Typography variant='body2' color='text.secondary'>
                      {item.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size='small'
                      href={item.link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {item.action}
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </Stack>
          )}
        </Stack>
        <Box style={{ paddingTop: '50px' }} />
      </Box>
    </Container>
  );
};

export default InstallationTimelineV2;
