import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ListItem, ListItemText, Stack, Typography } from '@mui/material';

export default function VendorProfileStatistics({ vendor }) {
  const statistics = [
    {
      value: vendor?.documents?.reference?.length,
      icon: <CheckCircleIcon fontSize='' sx={{ color: 'primary.main' }} />,
      description: (
        <span>
          verified <br /> installations
        </span>
      ),
    },
    {
      value: vendor?.rating ? (
        <span>
          {vendor?.rating}
          <span style={{ fontSize: '12px' }}>/5</span>
        </span>
      ) : null,
      icon: <CheckCircleIcon fontSize='' sx={{ color: 'primary.main' }} />,
      description: (
        <span>
          {vendor?.reviews?.length} verified <br /> reviews
        </span>
      ),
    },
    {
      value: vendor?.satisfactionScore ? (
        <span>
          {vendor?.satisfactionScore}{' '}
          <span style={{ fontSize: '12px' }}>/5</span>
        </span>
      ) : null,
      icon: <CheckCircleIcon fontSize='' sx={{ color: 'primary.main' }} />,
      description: (
        <span>
          Satisfaction <br /> score
        </span>
      ),
    },
  ].filter((item) => item.value);

  return (
    <Stack display='flex' justifyContent='space-between' direction='row'>
      {statistics
        .filter((item) => item.value)
        .map((item, index) => (
          <ListItem
            style={{ padding: '10px 0px 0px 0px', width: 'fit-content' }}
            key={index}
            alignItems='center'
          >
            <ListItemText
              sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              primary={
                <Typography
                  variant='h2'
                  color='primary.main'
                  sx={{
                    fontSize: {
                      xs: '34px',
                      md: '80px',
                    },
                    fontWeight: 'bold',
                  }}
                >
                  {item.value}
                </Typography>
              }
              secondary={
                <Typography variant='body2' fontWeight='bold' direction='row'>
                  {item.icon} {item.description}
                </Typography>
              }
            />
          </ListItem>
        ))}
    </Stack>
  );
}
