import { Box, Container, Stack } from '@mui/material';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { useEffect } from 'react';
import { usePageTitleStore } from '../../store/PageTitleStore';
import TabularBenefits from '../ui/TabularBenefits';

const BenefitsV2 = () => {
  const { submitIsClicked, setSubmitIsClicked, setActiveStep, activeStep } =
    useCustomerStore();
  const { updateTitle } = usePageTitleStore();

  useEffect(() => {
    updateTitle('Annual Benefits | VaySolar');
    setSubmitIsClicked(false);
  }, []);

  useEffect(() => {
    if (submitIsClicked) {
      setActiveStep(activeStep + 1);
      setSubmitIsClicked(false);
    }
  }, [submitIsClicked]);

  return (
    <Container sx={{ marginLeft: '0px !important' }}>
      <Box overflow='auto'>
        <Stack alignItems={'center'}>
          <TabularBenefits />
        </Stack>
      </Box>
    </Container>
  );
};

export default BenefitsV2;
