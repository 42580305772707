import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton, Box } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const ImagePreviewModal = ({ open, onClose, imageSrcArray }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = imageSrcArray.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='sm'
      sx={{
        borderRadius: '8px',
        backdropFilter: 'blur(2px)',
      }}
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
          bgcolor: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          aria-label='previous'
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ position: 'absolute', left: -6, zIndex: 1 }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height={{ sm: '50vh', xs: '40vh' }}
        >
          <img
            src={imageSrcArray[activeStep]}
            alt={`Preview`}
            style={{ maxWidth: '100%', height: '100%', objectFit: 'contain' }}
          />
        </Box>
        <IconButton
          aria-label='next'
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{ position: 'absolute', right: -6, zIndex: 1 }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </DialogContent>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{ position: 'absolute', top: 6, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

export default ImagePreviewModal;
