import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import imgSrc from '../../Image/orderConfirmed.svg';

const CongratulationsScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [countdown, setCountdown] = useState(10);

  const handleClick = () => {
    window.open('https://www.vaysolar.com/docs/buying-guide/', '_blank');
  };

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(intervalId);
          navigate('/dashboard');
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={4}
    >
      <Typography
        sx={{
          fontSize: {
            xs: theme.typography.body1.fontSize,
            md: theme.typography.h5.fontSize,
          },
          textAlign: 'center',
        }}
      >
        {t('main.congratulationsPage.text1')}{' '}
        <strong>{t('main.congratulationsPage.text2')}</strong>{' '}
        {t('main.congratulationsPage.text3')}{' '}
        {t('main.congratulationsPage.text4')}
        <br />
        {t('main.congratulationsPage.text5')}
      </Typography>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <img
          alt='Congratulations Message'
          src={imgSrc}
          style={{
            display: 'inline-block',
            maxWidth: '100%',
            width: 300,
          }}
        />
      </Box>
      <Typography
        sx={{
          fontSize: {
            xs: theme.typography.body1.fontSize,
            md: theme.typography.h5.fontSize,
          },
          textAlign: 'center',
        }}
      >
        {t('main.congratulationsPage.text6')}
        <br />
        <strong>{t('main.congratulationsPage.text7')}</strong>
      </Typography>
      <Stack spacing={1} direction={'row'}>
        <span
          style={{
            color: theme.palette.warning.main,
          }}
        >
          <AccessTimeIcon />
        </span>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.h6.fontSize,
            },
          }}
        >
          {t('main.congratulationsPage.text8')}
        </Typography>
      </Stack>
      <Typography
        sx={{
          fontSize: {
            xs: theme.typography.body1.fontSize,
            md: theme.typography.h5.fontSize,
          },
          textAlign: 'center',
        }}
      >
        Redirecting to the dashboard in {countdown} seconds...
      </Typography>
      <Button
        variant='contained'
        size={isMobile ? 'small' : 'large'}
        onClick={goToDashboard}
        sx={{
          gap: '0.5rem',
        }}
      >
        View your Dashboard
        <ArrowCircleRightIcon />
      </Button>
      <Button
        variant='text'
        size={isMobile ? 'small' : 'large'}
        onClick={handleClick}
        sx={{
          gap: '0.5rem',
        }}
      >
        {t('main.congratulationsPage.text9')}
        <ArrowCircleRightIcon />
      </Button>
    </Stack>
  );
};

export default CongratulationsScreen;
