import { ElectricalServices } from '@mui/icons-material';
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';
import PropertyDetails from '../ui/PropertyDetails';

const SanctionLoadV2 = () => {
  const {
    useFormInputs,
    activeStep,
    updateFormInputs,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const { updateTitle } = usePageTitleStore();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sanctionedLoadValidation = yup.object({
    sanctionedLoad: yup
      .number()
      .typeError(t('main.sanctionLoad.input.error'))
      .required(t('main.sanctionLoad.input.required')),
  });

  const formik = useFormik({
    initialValues: {
      sanctionedLoad: useFormInputs['propertyDetails']['sanctionedLoad'],
    },
    validationSchema: sanctionedLoadValidation,
    validateOnMount: true,
    onSubmit: (values) => {
      updateFormInputs({
        ...useFormInputs,
        propertyDetails: {
          ...useFormInputs['propertyDetails'],
          sanctionedLoad: values.sanctionedLoad,
        },
      });
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    },
    enableReinitialize: true,
  });

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.setFieldTouched('sanctionedLoad', true, false);
  };

  useEffect(() => {
    updateTitle('Sanctioned Load | VaySolar');
    setSubmitIsClicked(false);
  }, [setSubmitIsClicked, updateTitle, useFormInputs]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      if (formik.errors.sanctionedLoad) {
        formik.setFieldError('sanctionedLoad', formik.errors.phoneNumber);
      }
      setSubmitIsClicked(false);
    }
  }, [formik, setSubmitIsClicked, submitIsClicked]);

  useEffect(() => {
    if (formik.touched.sanctionedLoad && formik.values.sanctionedLoad) {
      setTimeout(() => {
        formik.handleSubmit();
      }, 500);
    }
  }, [formik.touched.sanctionedLoad, formik.values.sanctionedLoad, formik]);

  return (
    <Container>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.body1.fontSize,
            },
            fontWeight: 'bold',
            color: theme.palette.primary.main,
          }}
        >
          {t('main.common.feasibility')}
        </Typography>
        <PropertyDetails
          propertyDetails={useFormInputs.propertyDetails}
          siteVisitData={useFormInputs.siteVisitData}
        />
        <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
          <Typography
            sx={{
              marginTop: '80px',
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
            }}
          >
            {t('main.sanctionLoad.header')}
          </Typography>
        </Stack>
        <Box width='100%' maxWidth={'600px'}>
          <Stack
            direction='row'
            alignItems='center'
            spacing={2}
            marginTop='20px'
          >
            <span
              style={{
                color: theme.palette.primary.main,
              }}
            >
              <ElectricalServices />
            </span>
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('main.sanctionLoad.title')}</InputLabel>
              <Select
                label={t('main.sanctionLoad.title')}
                color='success'
                name='sanctionedLoad'
                value={formik.values.sanctionedLoad}
                onChange={handleChange}
              >
                {[...Array(50).keys()].map((value) => (
                  <MenuItem key={value + 1} value={value + 1}>
                    {value + 1} kW
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{t('main.sanctionLoad.subtitle')}</FormHelperText>
            </FormControl>
          </Stack>
        </Box>
        {formik.touched.sanctionedLoad && formik.errors.sanctionedLoad ? (
          <Typography
            sx={{ color: theme.palette.error.main, marginLeft: '2rem' }}
          >
            {formik.errors.sanctionedLoad}
          </Typography>
        ) : null}
      </Stack>
    </Container>
  );
};

export default SanctionLoadV2;
