import { ArrowCircleRight } from '@mui/icons-material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import successPage from '../../Image/successPage.svg';

const Success = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [countdown, setCountdown] = useState(10);

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(intervalId);
          navigate('/dashboard');
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  const solarBuyingGuideButton = (
    <Link to={'https://www.vaysolar.com/docs/buying-guide/'}>
      <Button
        variant='outlined'
        size='large'
        sx={{
          alignSelf: 'flex-start',
          mt: 4,
        }}
        disableElevation
        startIcon={<MenuBookIcon />}
      >
        {t('main.buttons.howItWorks')}
      </Button>
    </Link>
  );

  return (
    <Container
      sx={{
        minHeight: '70vh',
      }}
    >
      <Stack
        direction={{ sm: 'row', xs: 'column' }}
        spacing={2}
        alignItems={{
          sm: 'center',
          xs: 'flex-start',
        }}
        pb={4}
        justifyContent={'center'}
      >
        <Box
          src={successPage}
          alt=''
          component={'img'}
          width={{ md: '480px', sm: '320px', xs: '60%' }}
        />
        <Stack spacing={2} alignItems='stretch'>
          <Typography
            align='left'
            sx={{
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontFamily: theme.typography.h1.fontFamily,

              fontWeight: 400,
            }}
          >
            {t('main.success.title.text1')} <br />{' '}
            {t('main.success.title.text2')} <br />{' '}
            {t('main.success.title.text3')}{' '}
            <strong> {t('main.success.title.text4')}</strong>
          </Typography>
          {!isMobile && solarBuyingGuideButton}
        </Stack>
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.h5.fontSize,
            },
            textAlign: 'center',
            marginTop: '48px',
            marginBottom: '8px',
            alignSelf: 'flex-start',
          }}
        >
          Redirecting to the dashboard in {countdown} seconds...
        </Typography>
        <Button
          variant='contained'
          size='large'
          sx={{
            alignSelf: 'flex-start',
          }}
          disableElevation
          endIcon={<ArrowCircleRight />}
          onClick={goToDashboard}
        >
          View your Dashboard
        </Button>
        {isMobile && solarBuyingGuideButton}
      </Stack>
    </Container>
  );
};

export default Success;
