import apiClient from './ApiClient';

export const newCustomerRegistrationEventWhatsapp = async (customerId) => {
  const res = await apiClient.post('/events/register', {
    type: 'WHATSAPP',
    category: 'NEW_CUSTOMER_REGISTRATION',
    categoryFields: {
      customer_id: customerId,
    },
  });
  return res.data;
};

export const newCustomerRegistrationEventSms = async (customerId) => {
  const res = await apiClient.post('/events/register', {
    type: 'SMS',
    category: 'NEW_CUSTOMER_REGISTRATION',
    categoryFields: {
      customer_id: customerId,
    },
  });
  return res.data;
};
