import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { fadeAnimateVariants } from '../animate/animate';

const MiddleSec = () => {
  const iconSvgString2 = renderToStaticMarkup(
    <svg xmlns='http://www.w3.org/2000/svg' fill='#1B833312'>
      <ElectricalServicesIcon
        fontSize='inherit'
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    </svg>,
  );

  return (
    <Box
      sx={{
        background: `url(${`data:image/svg+xml,${encodeURIComponent(iconSvgString2)}`})`,
        backgroundSize: { md: '70%', xs: '100%' },
        backgroundPosition: { md: 'right top', xs: 'center' },
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
      }}
    >
      <Stack spacing={{ md: '155px', sm: '120px', xs: '110px' }} pt={'58px'}>
        <Typography
          variant='h3'
          fontSize={{
            md: '36px',
            sm: '30px',
            xs: '23px',
          }}
          component={motion.h3}
          variants={fadeAnimateVariants}
          initial='initial'
          whileInView='animate'
          viewport={{ once: true }}
          custom={1}
          fontWeight={'bold'}
        >
          With{' '}
          <Box
            component='span'
            sx={{
              color: 'primary.main',
            }}
          >
            100
          </Box>{' '}
          sq. feet used for{' '}
          <Box
            component='span'
            sx={{
              color: 'primary.main',
            }}
          >
            solar
          </Box>
        </Typography>
        <Grid container justifyContent={'space-between'}>
          <Grid item sm={8} xs={5}>
            <Stack spacing='3px'>
              <Typography
                variant='h6'
                component={motion.h6}
                variants={fadeAnimateVariants}
                initial='initial'
                whileInView='animate'
                viewport={{ once: true }}
                custom={1.5}
                fontSize={{ md: '18px', sm: '16px', xs: '14px' }}
                color='primary.main'
              >
                {' '}
                Yearly generation
              </Typography>
              <Typography
                variant='h6'
                component={motion.h6}
                variants={fadeAnimateVariants}
                initial='initial'
                whileInView='animate'
                viewport={{ once: true }}
                custom={2}
                fontSize={{ md: '18px', sm: '16px', xs: '14px' }}
              >
                <Box
                  component='span'
                  sx={{ fontSize: { md: '32px', sm: '28px', xs: '18px' } }}
                >
                  1500
                </Box>{' '}
                units
              </Typography>
              <Typography
                variant='h6'
                component={motion.h6}
                variants={fadeAnimateVariants}
                initial='initial'
                whileInView='animate'
                viewport={{ once: true }}
                custom={2.5}
                fontSize={{ md: '18px', sm: '16px', xs: '14px' }}
                color='primary.main'
              >
                {' '}
                AC always on for 1000+ hours
              </Typography>
            </Stack>
          </Grid>
          <Grid item sm={4} xs={5}>
            <Stack spacing='3px'>
              <Typography
                variant='h6'
                component={motion.h6}
                variants={fadeAnimateVariants}
                initial='initial'
                whileInView='animate'
                viewport={{ once: true }}
                custom={1.5}
                fontSize={{ md: '18px', sm: '16px', xs: '14px' }}
                color='primary.main'
              >
                {' '}
                Lifetime generation
              </Typography>
              <Typography
                variant='h6'
                component={motion.h6}
                variants={fadeAnimateVariants}
                initial='initial'
                whileInView='animate'
                viewport={{ once: true }}
                custom={2}
                fontSize={{ md: '18px', sm: '16px', xs: '14px' }}
              >
                <Box
                  component='span'
                  sx={{ fontSize: { md: '32px', sm: '28px', xs: '18px' } }}
                >
                  25000
                </Box>{' '}
                units
              </Typography>
              <Typography
                variant='h6'
                component={motion.h6}
                variants={fadeAnimateVariants}
                initial='initial'
                whileInView='animate'
                viewport={{ once: true }}
                custom={2.5}
                fontSize={{ md: '18px', sm: '16px', xs: '14px' }}
                color='primary.main'
              >
                {' '}
                AC always on for 600+ days
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} pt={{ md: 10, sm: 8, xs: 6 }} pb={2}>
            <Typography
              variant='h5'
              fontSize={{
                md: '24px',
                sm: '20px',
                xs: '16px',
              }}
              color='primary.main'
              component={motion.h5}
              variants={fadeAnimateVariants}
              initial='initial'
              whileInView='animate'
              viewport={{ once: true }}
              custom={2.5}
            >
              Savings @ INR 8 per unit{' '}
              <Box
                component='span'
                sx={{
                  fontWeight: 'bold',
                }}
              >
                before tax**
              </Box>
            </Typography>
          </Grid>
          <Grid item sm={8} xs={5}>
            <Typography
              variant='h6'
              component={motion.h6}
              variants={fadeAnimateVariants}
              initial='initial'
              whileInView='animate'
              viewport={{ once: true }}
              custom={3}
              fontSize={{
                md: '18px',
                sm: '15px',
                xs: '12px',
              }}
            >
              <Box
                component='span'
                sx={{
                  fontSize: { xs: '20px', sm: '28px', md: '32px' },
                  fontWeight: 'bold',
                  color: 'primary.main',
                }}
              >
                12,000+
              </Box>
              {'  '} INR
            </Typography>
          </Grid>
          <Grid item sm={4} xs={5}>
            <Typography
              variant='h6'
              component={motion.h6}
              variants={fadeAnimateVariants}
              initial='initial'
              whileInView='animate'
              viewport={{ once: true }}
              custom={4}
              fontSize={{
                md: '18px',
                sm: '15px',
                xs: '12px',
              }}
            >
              <Box
                component='span'
                sx={{
                  fontSize: { xs: '20px', sm: '28px', md: '32px' },
                  fontWeight: 'bold',
                  color: 'primary.main',
                }}
              >
                2,00,000+
              </Box>
              {'  '}INR
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant='h6'
          component={motion.h6}
          variants={fadeAnimateVariants}
          initial='initial'
          whileInView='animate'
          viewport={{ once: true }}
          custom={2}
          fontSize={{ md: '18px', sm: '16px', xs: '14px' }}
          color='primary.main'
          sx={{
            opacity: 0.4,
          }}
        >
          **taxes vary from 5% to 30% based on govt norms
        </Typography>
      </Stack>
    </Box>
  );
};

export default MiddleSec;
