import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { getPlantSizeOptions } from '../../services/CustomerService';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';

const PlantSizeOptions = () => {
  const { updateTitle } = usePageTitleStore();
  const {
    useFormInputs,
    updateFormInputs,
    activeStep,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const createOptions = (plantOption) => {
    const optionsList = [];
    if (plantOption.fullEnergyNeedsPlantSize) {
      const plantData = plantOption.fullEnergyNeedsPlantSize.plantData;
      const option = {
        plantSize: plantData.plantSize,
        title: '100% of energy needs',
        description: 'reduce bill by',
        billReduction: plantData.billReduction,
        firstYearSavings: plantData.firstYearSavings,
        energyConsumption: plantData.energyConsumption,
        energyProduction: plantData.energyProduction,
      };
      optionsList.push(option);
    }
    if (plantOption.loadPlantSize) {
      const plantData = plantOption.loadPlantSize.plantData;
      const option = {
        plantSize: plantData.plantSize,
        description: 'match existing load',
        billReduction: plantData.billReduction,
        firstYearSavings: plantData.firstYearSavings,
        energyConsumption: plantData.energyConsumption,
        energyProduction: plantData.energyProduction,
        title: `${((plantData.energyProduction / plantData.energyConsumption) * 100).toFixed(2)}% of energy needs`,
      };
      optionsList.push(option);
    }
    //TODO: We will need this later, not now so comemnting it out
    // if (plantOption.roofAreaPlantSize) {
    //   const plantData = plantOption.roofAreaPlantSize.plantData;
    //   const option = {
    //     plantSize: plantData.plantSize,
    //     description: 'reduce bill by',
    //     billReduction: plantData.billReduction,
    //     firstYearSavings: plantData.firstYearSavings,
    //     energyConsumption: plantData.energyConsumption,
    //     energyProduction: plantData.energyProduction,
    //     title: `${((plantData.energyProduction / plantData.energyConsumption) * 100).toFixed(2)}% of energy needs`,
    //   };
    //   optionsList.push(option);
    // }
    return optionsList;
  };

  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please select an option'),
  });

  useEffect(() => {
    const onMount = async () => {
      try {
        const region = useFormInputs.propertyDetails.address?.state || 'Delhi';
        const propertyType =
          useFormInputs.propertyDetails.propertyType || 'RESIDENTIAL';
        const billAmount = useFormInputs.propertyDetails.billAmount || 2000;
        const billMonth = useFormInputs.propertyDetails.billMonth || 'JANUARY';
        const sanctionedLoad =
          useFormInputs.propertyDetails.sanctionedLoad || 3;
        const shadowFreeArea =
          useFormInputs.propertyDetails.shadowFreeArea || 500;
        const loanPercentage = 0; //TODO: get from formik
        const data = await getPlantSizeOptions(
          region,
          propertyType,
          billAmount,
          billMonth,
          sanctionedLoad,
          shadowFreeArea,
          loanPercentage,
        );
        if (data.length === 0) throw new Error('No data found');
        setOptions(createOptions(data.plantOptions));
      } catch (error) {
        toast.error(error);
      }
    };
    onMount();
  }, []);

  useEffect(() => {
    updateTitle('Select plant size | VaySolar');
    setSubmitIsClicked(false);
  }, [setSubmitIsClicked, updateTitle]);

  const handleCardClick = (index) => {
    const newSelectedOption = selectedOption === index ? null : index;
    setSelectedOption(selectedOption === index ? null : index);
    formik.setFieldValue('selectedOption', newSelectedOption);
  };

  const formik = useFormik({
    initialValues: {
      selectedOption: selectedOption,
    },
    validationSchema,
    onSubmit: (values) => {
      updateFormInputs({
        ...useFormInputs,
        siteVisitData: {
          ...useFormInputs['siteVisitData'],
          recommendedLoad: options[values.selectedOption].plantSize,
        },
      });
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      setSubmitIsClicked(false);
    }
  }, [formik, setSubmitIsClicked, submitIsClicked]);

  return (
    <Container>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h4.fontSize,
              md: theme.typography.h3.fontSize,
            },
            fontWeight: 'bold',
            marginTop: '80px',
          }}
        >
          Select plant size
        </Typography>
        <Stack alignItems={isMobile ? 'flex-start' : 'center'} width={'100%'}>
          {options.map((option, index) => (
            <Card
              key={index}
              sx={{
                backgroundColor:
                  selectedOption === index
                    ? theme.palette.secondary.lighter
                    : theme.palette.background.paper,
                cursor: 'pointer',
                width: '100%',
                marginTop: 2,
                borderRadius: '10px',
                boxShadow: '2px 2px 2px 2px #D3D3D3',
                maxWidth: '500px',
              }}
              onClick={() => handleCardClick(index)}
            >
              <CardContent>
                <Typography sx={{ fontWeight: 'bold', paddingBottom: '4px' }}>
                  <Typography component='span' sx={{ color: 'green' }}>
                    {option.plantSize}
                  </Typography>
                  , {option.title}
                </Typography>
                <Typography variant='caption'>
                  {option.description} {option.billReduction},{' '}
                  {option.firstYearSavings}
                </Typography>
                {selectedOption === index && (
                  <>
                    <Grid container mt={2}>
                      <Grid item xs={9}>
                        <Typography variant='caption' sx={{ color: 'green' }}>
                          Annual energy needs
                        </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'right' }}>
                        <Typography
                          variant='caption'
                          sx={{ fontWeight: 'bold' }}
                        >
                          {option.energyConsumption.toLocaleString()} units
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={9}>
                        <Typography variant='caption' sx={{ color: 'green' }}>
                          {option.plantSize} solar plant production
                        </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'right' }}>
                        <Typography
                          variant='caption'
                          sx={{ fontWeight: 'bold' }}
                        >
                          {option.energyProduction.toLocaleString()} units
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          ))}
        </Stack>
        {formik.touched.selectedOption && formik.errors.selectedOption ? (
          <Typography sx={{ color: theme.palette.error.main, mt: 1 }}>
            {formik.errors.selectedOption}
          </Typography>
        ) : null}
      </Stack>
    </Container>
  );
};

export default PlantSizeOptions;
