import { Engineering, Security, Verified } from '@mui/icons-material';
import { Box, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { toSentenceCase } from '../../utils/utils';
import { useTheme } from '@mui/material';

const VendorBusinessDocuments = ({ vendor }) => {
  const theme = useTheme();
  const documents = [
    {
      icon: (
        <Verified
          sx={{ fontSize: { xs: '1.7rem', md: '2.3rem' } }}
          style={{ paddingTop: '6px' }}
        />
      ),
      title: (
        <>
          <b>Verified documents ({vendor?.documents?.identity?.length})</b>
          <Typography color={theme.palette.text.secondary}>
            {vendor?.documents?.identity?.map((identity, index) => (
              <React.Fragment key={index}>
                {identity.name}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </>
      ),
    },
    {
      icon: (
        <Engineering
          sx={{ fontSize: { xs: '1.7rem', md: '2.3rem' } }}
          style={{ paddingTop: '6px' }}
        />
      ),
      title: (
        <>
          <b>
            Past installation proofs ({vendor?.documents?.reference?.length})
          </b>{' '}
          <Typography color={theme.palette.text.secondary}>
            {vendor?.documents?.reference
              ?.slice(0, 2)
              .map((reference, index) => (
                <React.Fragment key={index}>
                  {reference.name} .{' '}
                  <b>{toSentenceCase(reference?.verificationStatus)}</b>
                  <br />
                </React.Fragment>
              ))}
            {vendor?.documents?.reference?.length > 2 && (
              <Typography>
                and {vendor?.documents?.reference?.length - 2} more
              </Typography>
            )}
          </Typography>
        </>
      ),
    },
    {
      icon: (
        <Security
          sx={{ fontSize: { xs: '1.7rem', md: '2.3rem' } }}
          style={{ paddingTop: '6px' }}
        />
      ),
      title: (
        <>
          <b>
            Certifications ({vendor?.documents?.certifications?.length || 0})
          </b>
          <Typography color={theme.palette.text.secondary}>
            {vendor?.documents?.certifications?.map((certification, index) => (
              <React.Fragment key={index}>
                {certification.name}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </>
      ),
    },
  ];

  return (
    <Box padding='0px'>
      <List fontSize='30px'>
        {documents.map((item, index) => (
          <>
            <ListItem
              key={index}
              sx={{
                padding: '8px 0px',
                color: 'primary.main',
                alignItems: 'flex-start',
              }}
            >
              {item.icon}
              <Typography
                variant='body2'
                sx={{
                  paddingLeft: '23px',
                  color: 'text.primary',
                  fontWeight: '200',
                  fontSize: { xs: 'unset', md: '1.1rem' },
                }}
              >
                {item.title}
              </Typography>
            </ListItem>
          </>
        ))}
      </List>
    </Box>
  );
};

export default VendorBusinessDocuments;
