import { Box, Container, Stack, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import SEO from './SEO';

export const AuthLayout = ({ children, action = '' }) => {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const history = useNavigate();

  const mobileHeaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    py: 3,
  };

  const defaultHeaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    py: 3,
  };

  return (
    <>
      <SEO
        title={`VaySolar | ${action}`}
        description='VaySolar a platform for vendor and customer to unite world with solar power energy'
        name='VaySolar'
        type='article'
      />
      <Box sx={{ bgcolor: 'white', maxWidth: '1024px', mx: 'auto' }}>
        <Container
          sx={{
            paddingInline: '0px !important',
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} px={1}>
            <Box
              component='header'
              sx={smDown ? mobileHeaderStyle : defaultHeaderStyle}
              onClick={() => history({ pathname: '/' })}
            >
              <Logo />
            </Box>
          </Stack>
          {children}
        </Container>
      </Box>
    </>
  );
};
