import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

const SiteVisitDialog = ({ open, setOpen, handleNext }) => {
  return (
    <Dialog
      open={open}
      maxWidth={'xs'}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          borderRadius: '8px',
          paddingBlock: '16px',
          paddingInline: { sm: '16px', xs: '3px' },
        },
      }}
      sx={{
        backdropFilter: 'blur(2px)',
      }}
    >
      <DialogTitle component={'div'}>
        <span>
          <MapsHomeWorkIcon />
        </span>
        <Typography
          pl={'10px'}
          component={'span'}
          variant='h6'
          fontWeight={'bold'}
        >
          Site details not found
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          For accurate quotes, add site details
        </Typography>
        <Typography
          variant='body2'
          pt='3px'
          sx={{
            color: 'text.secondary',
          }}
        >
          These details determine structure needs, wire lengths, battery needs,
          at all. that affect final prices
        </Typography>
        <Stack
          direction={'row'}
          pt={'22px'}
          alignItems={'start'}
          justifyContent={'flex-end'}
          spacing={1}
        >
          <Button onClick={() => setOpen(false)} size='small'>
            Cancel
          </Button>
          <Stack>
            <Button
              variant='contained'
              size='small'
              onClick={handleNext}
              endIcon={<NavigateNextIcon />}
              disableElevation
            >
              Next
            </Button>
            <Typography
              variant='caption'
              sx={{
                color: 'text.secondary',
                pt: '1.5px',
              }}
            >
              takes 2 minutes
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SiteVisitDialog;
