import { Box, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../Image/company_logo.png';

const Logo = ({ placement = 'center', hght = '64px' }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const history = useNavigate();

  const originalWidth = 144;
  const originalHeight = 56;

  const width = isMobile ? 144 : 190;
  const height = (width / originalWidth) * originalHeight;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={placement}
      flexDirection={'row'}
      height={hght}
      onClick={() => {
        history({ pathname: '/' });
      }}
    >
      <Box
        component='header'
        sx={{
          display: { xs: 'flex' },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={logo}
          alt='Logo'
          style={{
            width: `${width}px`,
            height: `${height}px`,
            cursor: 'pointer',
          }}
        />
      </Box>
    </Box>
  );
};
export default Logo;
