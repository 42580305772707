import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '7px',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  bgcolor: 'background.paper',
};

const mobileStyle = {
  ...style,
  width: '90%',
};

const desktopStyle = {
  ...style,
  width: 500,
};

const InformationModal = (props) => {
  const { open, onClose, children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          ...(isMobile ? mobileStyle : desktopStyle),
        }}
      >
        {children}
        <Button
          sx={{
            width: 'fit-content',
            gap: '8px',
          }}
          onClick={onClose}
        >
          {t('main.buttons.goBack')}
          <CancelIcon fontSize='small' />
        </Button>
      </Box>
    </Modal>
  );
};

export default InformationModal;
