import apiClient from './ApiClient';

export const generateOtp = async (phoneNumber) => {
  const res = await apiClient.get(
    '/auth/generate-otp?phoneNumber=' + phoneNumber,
  );
  return res.data;
};

export const loginWithOtp = async (phoneNumber, otp) => {
  const res = await apiClient.post('/auth/login/otp', { phoneNumber, otp });
  return res.data;
};
