import { create } from 'zustand';

const initialState = {
  fullName: null,
  phoneNumber: null,
  email: null,
  prefCommChannel: null,
  numberOfSolarInstallers: null,
  region: '',
  propertyDetails: {
    id: null,
    propertyType: null,
    billAmount: 0,
    billMonth: '',
    sanctionedLoad: '',
    pinCode: '',
    shadowFreeArea: 0,
    address: {},
    consumptionPattern: null,
    installationTimeline: null,
  },
  siteVisitData: {
    roofType: '',
    roofTopArea: '',
    floor: '',
    roofUsage: '',
    powerCutFrequency: '',
    recommendedLoad: '',
  },
};

export const useCustomerStore = create((set) => ({
  useFormInputs: initialState,
  finalFlag: false,
  activeStep: 0,
  submitIsClicked: false,
  isSaveDetailsButtonDisabled: false,
  isStepperHidden: false,
  updateFormInputs: (newInputs) =>
    set((state) => ({ useFormInputs: newInputs })),
  resetFormInputs: () => set(() => ({ useFormInputs: initialState })),
  setActiveStep: (newInputs) => set((state) => ({ activeStep: newInputs })),
  setFinalFlag: (newInputs) => set((state) => ({ finalFlag: newInputs })),
  setSubmitIsClicked: (newInputs) =>
    set((state) => ({ submitIsClicked: newInputs })),
  setIsSaveDetailsButtonDisabled: (newInputs) =>
    set(() => ({ isSaveDetailsButtonDisabled: newInputs })),
  setIsStepperHidden: (newInputs) =>
    set(() => ({ isStepperHidden: newInputs })),
}));
