import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';

export default function LanguageMenu() {
  const { lang } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLanguage, setSelectedLanguage] = React.useState(lang || 'en');
  const { i18n } = useTranslation();

  const languageMapping = {
    en: 'English',
    hi: 'हिन्दी',
    ta: 'தமிழ்',
  };

  React.useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} endIcon={<LanguageIcon />}>
        {languageMapping[selectedLanguage]}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(selectedLanguage)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleClose('en')}>English</MenuItem>
        <MenuItem onClick={() => handleClose('hi')}>हिन्दी</MenuItem>
        <MenuItem onClick={() => handleClose('ta')}>தமிழ்</MenuItem>
      </Menu>
    </>
  );
}
