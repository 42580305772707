import { useTheme } from '@emotion/react';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import {
  Box,
  Card,
  CardActions,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { downloadDocument } from '../../services/CustomerService';
import { truncateText } from '../../utils/utils';
import ImagePreviewModal from './ImagePreviewModel';
const SitePhoto = ({ vendor }) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const plantScroll = useRef(null);
  const [disableLeft, setDisableLeft] = useState(true);
  const [disableRight, setDisableRight] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [imgArray, setImgArray] = useState([]);

  const handleModalOpen = async (images) => {
    try {
      const downloadPromises = images.map(async (photos) => {
        const data = await downloadDocument(photos.id);
        return data;
      });
      const siteImageData = await Promise.all(downloadPromises);
      setImgArray(siteImageData);
      setOpenModal(true);
    } catch (error) {
      console.error('Error downloading site photos:', error);
    }
    console.log(images);
  };

  const handleModalClose = () => {
    setImgArray([]);
    setOpenModal(false);
  };

  const scrollLeft = () => {
    if (plantScroll.current) {
      plantScroll.current.scrollLeft -= 200;
    }
  };

  const scrollRight = () => {
    if (plantScroll.current) {
      plantScroll.current.scrollLeft += 200;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (plantScroll.current) {
        const scrollLeft = plantScroll.current.scrollLeft;
        const scrollWidth = plantScroll.current.scrollWidth;
        const clientWidth = plantScroll.current.clientWidth;

        setDisableLeft(scrollLeft <= 0);
        setDisableRight(scrollLeft + clientWidth >= scrollWidth);
        setShowButtons(scrollWidth > clientWidth);
      }
    };

    if (plantScroll.current) {
      plantScroll.current.addEventListener('scroll', handleScroll);
      handleScroll();
    }
    return () => {
      if (plantScroll.current) {
        plantScroll.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Box pt={1}>
      <Stack
        component='div'
        pt={'10px'}
        pb={'20px'}
        spacing={'8px'}
        borderTop={'1px solid #0000001F'}
      >
        <Stack
          justifyContent='space-between'
          alignItems='center'
          pb={'8px'}
          spacing={1}
          direction='row'
        >
          <Stack>
            <Typography
              fontSize={theme.typography.h6.fontSize}
              fontWeight={theme.typography.overline.fontWeight}
              color={theme.palette.text.primary}
              mt={2}
              mb={2}
            >
              {vendor?.siteDetails?.photos?.length} Installed plant photos
            </Typography>
            {vendor?.siteDetails?.length > 0 && (
              <Box display='flex' flexDirection='column' mb={2}>
                <Typography
                  fontSize={theme.typography.subtitle1.fontSize}
                  fontWeight='500'
                >
                  {vendor?.siteDetails?.length}&nbsp; sites added
                </Typography>
              </Box>
            )}

            {vendor?.siteDetails?.filter(
              (site) => site.verificationStatus === 'VERIFIED',
            ).length > 0 && (
              <Box display='flex' flexDirection='column' mb={2}>
                <Typography
                  fontSize={theme.typography.subtitle1.fontSize}
                  fontWeight='500'
                >
                  {
                    vendor?.siteDetails?.filter(
                      (site) => site.verificationStatus === 'VERIFIED',
                    ).length
                  }{' '}
                  &nbsp; verified with proofs
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>
        <Stack>
          <Stack alignItems='center' direction='row'>
            {showButtons && !disableLeft && (
              <IconButton
                onClick={scrollLeft}
                sx={{
                  mr: 2,
                }}
                size='small'
                disableRipple
              >
                <ArrowBackIosOutlinedIcon fontSize='small' color='primary' />
              </IconButton>
            )}
            <Grid
              container
              spacing={1.5}
              wrap='nowrap'
              ref={plantScroll}
              gap={2}
              sx={{
                overflowX: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollBehavior: 'smooth',
                p: 1,
              }}
            >
              {vendor?.siteDetails?.length ? (
                vendor?.siteDetails?.map((site) => (
                  <Card
                    key={site.id}
                    sx={{
                      maxWidth: 300,
                      minWidth: 100,
                      p: 1,
                      boxShadow: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleModalOpen(site.photos)}
                  >
                    <CardMedia
                      component='img'
                      sx={{ height: 100, borderRadius: '8px' }}
                      image={site.photos[0]?.url}
                      title={site.displayName}
                    />
                    <CardActions>
                      <Typography
                        variant='body2'
                        component={'p'}
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {truncateText(site.displayName, 40)} <br />
                        {site.location} <br />
                        {site.verificationStatus === 'PENDING' ? (
                          <WatchLaterIcon fontSize='small' color='warning' />
                        ) : site.verificationStatus === 'VERIFIED' ? (
                          <CheckCircleIcon fontSize='small' color='primary' />
                        ) : (
                          <CancelIcon fontSize='small' color='error' />
                        )}
                      </Typography>
                    </CardActions>
                  </Card>
                ))
              ) : (
                <Typography
                  variant='body1'
                  component='p'
                  sx={{ wordBreak: 'break-word', pl: '4px' }}
                >
                  No site photos to show
                </Typography>
              )}
            </Grid>
            {showButtons && !disableRight && (
              <IconButton
                onClick={scrollRight}
                sx={{
                  ml: 2,
                }}
                size='small'
                disableRipple
              >
                <ArrowForwardIosOutlinedIcon fontSize='small' color='primary' />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Stack>

      <ImagePreviewModal
        open={openModal}
        onClose={handleModalClose}
        imageSrcArray={imgArray}
      />
    </Box>
  );
};

export default SitePhoto;
