import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import googleIcon from '../Image/google.svg';
import { AuthLayout } from '../layout/AuthLayout';
import { generateOtp, loginWithOtp } from '../services/AuthService';

const LoginPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [googleLoginError, setGoogleLoginError] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(30);

  const isDevEnv = process.env.REACT_APP_ENVIRONMENT === 'development';

  useEffect(() => {
    let timer;
    if (otpSent && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [countdown, otpSent]);

  const handleSignInWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const res = await fetch(
          process.env.REACT_APP_BE_SERVER_BASE_URL + '/auth/login/google',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: codeResponse.code }),
          },
        );

        if (!res.ok) {
          const errorResponse = await res.json();
          await Promise.resolve();
          throw new Error(errorResponse);
        } else {
          const data = await res.json();
          if (data.accessToken && data.refreshToken) {
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            navigate('/dashboard');
          }
        }
      } catch (error) {
        setGoogleLoginError('Failed to sign in with Google. Please try again.');
      }
    },
    onError: () => {
      setGoogleLoginError('Failed to sign in with Google. Please try again.');
    },
    flow: 'auth-code',
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      otp: '',
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, t('main.contactYou.input.errorPhone'))
        .required(t('main.contactYou.input.errorType')),
      otp: Yup.string()
        .matches(/^\d{6}$/, t('main.contactYou.input.errorOtp'))
        .required(t('main.contactYou.input.errorValidOtp')),
    }),
    onSubmit: async (values) => {
      try {
        const res = await loginWithOtp(
          '91' + values.phoneNumber,
          Number(values.otp),
        );
        if (res.accessToken && res.refreshToken) {
          localStorage.setItem('accessToken', res.accessToken);
          localStorage.setItem('refreshToken', res.refreshToken);
          navigate('/dashboard');
        }
      } catch (err) {
        formik.setFieldError('otp', err.response.data.message);
      }
    },
  });

  const handleSendOTP = async () => {
    formik.setFieldTouched('phoneNumber', true);
    formik.setFieldValue('otp', '');
    formik.setFieldTouched('otp', false);
    await formik.validateField('phoneNumber');

    if (!formik.errors.phoneNumber && formik.values.phoneNumber.length === 10) {
      try {
        await generateOtp('91' + formik.values.phoneNumber);
        setOtpSent(true);
        setResendDisabled(true);
        setCountdown(30);
      } catch (err) {
        console.log(err);
      }
    } else {
      formik.setFieldError(
        'phoneNumber',
        t('main.contactYou.input.errorPhone'),
      );
    }
  };

  return (
    <AuthLayout action='Login'>
      <Box maxWidth='400px' width='100%' margin='auto' px={isMobile && 5}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '26px',
            paddingBottom: '30px',
            textAlign: 'center',
          }}
        >
          Sign In
        </Typography>
        {isDevEnv && (
          <>
            <Button
              variant='outlined'
              onClick={() => handleSignInWithGoogle()}
              startIcon={<img src={googleIcon} alt='google logo' />}
              sx={{
                width: '100%',
                alignSelf: 'center',
                marginBottom: !googleLoginError && '40px',
              }}
            >
              Sign in with Google
            </Button>
            {googleLoginError && (
              <Typography
                color='error'
                variant='body2'
                marginTop='10px'
                marginBottom={googleLoginError && '40px'}
              >
                {googleLoginError}
              </Typography>
            )}
            <Divider sx={{ marginBottom: '30px' }}>
              <Typography variant='body2' color='gray'>
                OR
              </Typography>
            </Divider>
          </>
        )}
        <form onSubmit={formik.handleSubmit} sx={{ mt: 30 }}>
          <Stack spacing={3}>
            <TextField
              size='small'
              error={
                !!(formik.touched.phoneNumber && formik.errors.phoneNumber)
              }
              fullWidth
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              label='Phone Number'
              name='phoneNumber'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='tel'
              inputMode='numeric'
              placeholder='__________'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>+91</InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 10,
                style: { textalign: 'left', letterSpacing: '0.1rem' },
              }}
              value={formik.values.phoneNumber}
              disabled={otpSent}
            />
            {otpSent && (
              <>
                <TextField
                  id='otp'
                  size='small'
                  error={!!(formik.touched.otp && formik.errors.otp)}
                  helperText={formik.touched.otp && formik.errors.otp}
                  label='Enter OTP'
                  name='otp'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='tel'
                  inputMode='numeric'
                  value={formik.values.otp}
                  placeholder='______'
                  fullWidth
                  inputProps={{
                    maxLength: 6,
                    style: { textalign: 'left', letterSpacing: '1rem' },
                  }}
                />
                <Button
                  variant='text'
                  disabled={resendDisabled}
                  onClick={handleSendOTP}
                  sx={{
                    textTransform: 'none',
                    alignSelf: 'flex-start',
                  }}
                >
                  GET OTP {resendDisabled ? `in ${countdown}s` : ''}
                </Button>
              </>
            )}
            <Button
              fullWidth
              size='md'
              sx={{ mt: 3 }}
              variant='contained'
              onClick={!otpSent ? handleSendOTP : formik.handleSubmit}
            >
              {otpSent ? 'Continue' : 'GET OTP'}
            </Button>
            <Divider sx={{ pt: '30px' }}>
              <Typography variant='body2' color='gray'>
                Don&apos;t have an account?
              </Typography>
            </Divider>
            <Button
              fullWidth={false}
              size='md'
              sx={{
                mt: 3,
                color: 'accent.primary',
                borderColor: 'accent.primary',
              }}
              variant='outlined'
              onClick={() => navigate('/start')}
            >
              Start your journey
            </Button>
          </Stack>
        </form>
      </Box>
    </AuthLayout>
  );
};

export default LoginPage;
