export const PowerCutFrequency = {
  NO: 'NO',
  RARE: 'RARE',
  FREQUENT: 'FREQUENT',
};

export const RoofUsageFrequency = {
  NO: 'NO',
  RARE: 'RARE',
  FREQUENT: 'FREQUENT',
};

export const ElectricityConsumptionPattern = {
  CONSTANT: 'CONSTANT',
  VARIABLE: 'VARIABLE',
};

export const PropertyTypeEnum = {
  RESIDENTIAL: 'RESIDENTIAL',
  COMMERCIAL: 'COMMERCIAL',
};

export const VerificationStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  VERIFIED: 'VERIFIED',
};

export const Interaction = {
  CUSTOMER_INITIATED_CALL: 'CUSTOMER_INITIATED_CALL',
  VENDOR_INITIATED_CALL: 'VENDOR_INITIATED_CALL',
  SEND_REMINDER_CUSTOMER_VENDOR_PROPOSAL:
    'SEND_REMINDER_CUSTOMER_VENDOR_PROPOSAL',
};
