import { useEffect } from 'react';
import JourneyLayout from '../layout/JourneyLayout';
import { useCustomerStore } from '../store/CustomerJourneyStore';
import { usePageTitleStore } from '../store/PageTitleStore';
import Success from '../components/ui/Success';

const SuccessPage = () => {
  const { resetFormInputs } = useCustomerStore();
  const { updateTitle } = usePageTitleStore();

  useEffect(() => {
    updateTitle('Success | VaySolar');
  }, [updateTitle, resetFormInputs]);

  return (
    <JourneyLayout>
      <Success />
    </JourneyLayout>
  );
};
export default SuccessPage;
