import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';
import { PowerCutFrequency } from '../../utils/app.enums';
import PropertyDetails from '../ui/PropertyDetails';

import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Bulb from '../../Image/electricityBulb.svg';
import InformationModal from '../ui/InformationModal';

const PowerCuts = () => {
  const {
    useFormInputs,
    activeStep,
    updateFormInputs,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const { updateTitle } = usePageTitleStore();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required(t('main.error.selectOption')),
  });

  const formik = useFormik({
    initialValues: {
      selectedOption: useFormInputs.siteVisitData.powerCutFrequency,
    },
    validationSchema,
    onSubmit: (values) => {
      updateFormInputs({
        ...useFormInputs,
        siteVisitData: {
          ...useFormInputs['siteVisitData'],
          powerCutFrequency: values.selectedOption.toString(),
        },
      });
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    },
    enableReinitialize: true,
  });

  const options = [
    {
      name: (
        <>
          <strong>{t('main.common.barely')}</strong>
          {' ('}
          {t('main.common.5hrsMonth')}
          {')'}
        </>
      ),
      value: PowerCutFrequency.NO,
    },
    {
      name: (
        <>
          <strong>{t('main.common.rarely')}</strong>
          {' ('}
          {t('main.common.5hrsWeek')}
          {')'}
        </>
      ),
      value: PowerCutFrequency.RARE,
    },
    {
      name: (
        <>
          <strong>{t('main.common.frequently')}</strong>
          {' ('}
          {t('main.common.5hrsDay')}
          {')'}
        </>
      ),
      value: PowerCutFrequency.FREQUENT,
    },
  ];

  useEffect(() => {
    updateTitle('Power Cut Frequency | VaySolar');
    setSubmitIsClicked(false);
  }, [setSubmitIsClicked, updateTitle, useFormInputs]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      setSubmitIsClicked(false);
    }
  }, [submitIsClicked, formik, setSubmitIsClicked]);

  return (
    <Container>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.body1.fontSize,
            },
            fontWeight: 'bold',
            color: theme.palette.primary.main,
          }}
        >
          {t('main.roofType.title')}
        </Typography>
        <PropertyDetails
          propertyDetails={useFormInputs.propertyDetails}
          siteVisitData={useFormInputs.siteVisitData}
        />
        <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
              marginTop: '100px',
            }}
          >
            {t('main.powerCuts.title1')}{' '}
            <strong>{t('main.powerCuts.title2')}</strong>{' '}
            {t('main.powerCuts.title3')}
            <IconButton aria-label='delete' onClick={() => handleModalOpen()}>
              <HelpIcon sx={{ color: theme.palette.black }} />
            </IconButton>
            <InformationModal open={openModal} onClose={handleModalClose}>
              <ModalBody />
            </InformationModal>
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: {
                xs: theme.typography.subtitle1.fontSize,
                md: theme.typography.subtitle1.fontSize,
              },
              marginBottom: '20px',
            }}
          >
            {t('main.powerCuts.subtitle')}
          </Typography>
        </Stack>

        <Stack
          spacing={1}
          width='100%'
          maxWidth={'600px'}
          alignItems={isMobile ? 'flex-start' : 'center'}
        >
          {options.map((option, index) => (
            <RadioGroup
              key={index}
              value={formik.values.selectedOption ?? ''}
              onChange={(event) => {
                formik.setFieldValue('selectedOption', event.target.value);
                formik.setFieldTouched('selectedOption');
                setTimeout(formik.handleSubmit, 0);
              }}
            >
              <FormControlLabel
                value={option.value}
                control={<Radio />}
                label={<Typography>{option.name}</Typography>}
              />
            </RadioGroup>
          ))}
        </Stack>
        {formik.touched.selectedOption && formik.errors.selectedOption ? (
          <Typography sx={{ color: theme.palette.error.main }}>
            {formik.errors.selectedOption}
          </Typography>
        ) : null}
      </Stack>
    </Container>
  );
};

const ModalBody = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Stack direction='column'>
        <Typography
          id='modal-modal-title'
          variant='h5'
          component='h2'
          sx={{ marginBottom: '30px' }}
        >
          {t('main.powerCuts.informationModal.title1')}
        </Typography>
        <Typography
          id='modal-modal-description'
          sx={{ mt: 2, color: theme.palette.primary.main }}
        >
          {t('main.powerCuts.informationModal.text1')}
        </Typography>
        <Typography
          id='modal-modal-description'
          sx={{ mt: 2, color: theme.palette.primary.main }}
        >
          {t('main.roofUsage.informationModal.text2')}
        </Typography>

        <img
          src={Bulb}
          alt=''
          style={{
            display: 'inline-block',
            maxWidth: '100%',
            width: 150,
            opacity: '0.8',
            marginTop: '100px',
          }}
        ></img>
      </Stack>
    </>
  );
};

export default PowerCuts;
