import { create } from 'zustand';

export const useSearchAnimate = create((set) => ({
  isSearchOver: true,
  setIsSearchOver: (isSearch) => set(() => ({ isSearchOver: isSearch })),
  isLooking: false,
  setIsLooking: (isLook) => set(() => ({ isLooking: isLook })),
  isAlert: { show: false, msg: '' },
  setIsAlert: (isOpen) => set(() => ({ isAlert: isOpen })),
}));
