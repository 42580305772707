import { useTheme } from '@emotion/react';
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import VendorHeader from '../components/vendor-details/VendorHeader';
import { DashboardLayout } from '../layout/DashboardLayout';
import { getVendorList } from '../services/CustomerService';
import { usePageTitleStore } from '../store/PageTitleStore';

const VendorListPage = () => {
  const [vendorList, setVendorList] = useState([]);
  const theme = useTheme();
  const { updateTitle } = usePageTitleStore();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    updateTitle('Vendors | VaySolar');
  }, [updateTitle]);

  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);
      try {
        const vendorData = await getVendorList();
        setVendorList(vendorData);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    onMount();
  }, []);

  return (
    <DashboardLayout>
      {isLoading ? (
        <CircularProgress
          sx={{ alignSelf: 'center', justifyContent: 'center' }}
        />
      ) : (
        <>
          <Container px={2} py={2}>
            <Typography
              marginBottom={2}
              mt={1}
              pl={isMobile ? 0 : 3}
              sx={{
                color: theme.palette.primary.main,
                fontSize: {
                  xs: theme.typography.h6.fontSize,
                  md: theme.typography.h5.fontSize,
                },
              }}
            >
              {vendorList.length === 0
                ? 'No installers available in your region'
                : `${vendorList.length} installers in your region`}
            </Typography>
            <Box>
              {vendorList.map((vendor) => (
                <>
                  <VendorHeader key={vendor.id} vendor={vendor} />
                  <br />
                </>
              ))}
            </Box>
          </Container>
        </>
      )}
    </DashboardLayout>
  );
};

export default VendorListPage;
