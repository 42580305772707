import { Divider } from '@mui/material';
import React, { useEffect } from 'react';
import BottomSec from '../components/landing/BottomSec';
import MiddleSec from '../components/landing/MiddleSec';
import TopSec from '../components/landing/TopSec';
import MainLayout from '../layout/MainLayout';
import { usePageTitleStore } from '../store/PageTitleStore';

const LandingPage = () => {
  const { updateTitle } = usePageTitleStore();

  useEffect(() => {
    updateTitle('Easiest way to go solar!');
  }, []);

  return (
    <MainLayout>
      <TopSec startLink={'/start'} />
      <Divider />
      <MiddleSec />
      <Divider />
      <BottomSec startLink={'/start'} />
    </MainLayout>
  );
};

export default LandingPage;
