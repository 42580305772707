import React from 'react';
import JourneyStepper from '../components/JourneyStepper';
import JourneyLayout from '../layout/JourneyLayout';

const CustomerJourney = () => {
  return (
    <JourneyLayout>
      <JourneyStepper />
    </JourneyLayout>
  );
};

export default CustomerJourney;
