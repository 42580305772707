import React from 'react';
import { Box, Container } from '@mui/material';
import SEO from './SEO';
import { usePageTitleStore } from '../store/PageTitleStore';
import Footer from '../components/Footer';

const MainLayout = ({ children }) => {
  const { useTitle } = usePageTitleStore();

  return (
    <>
      <SEO
        title={useTitle}
        description='VaySolar a platform for vendor and customer to unite world with solar power energy'
        name='VaySolar'
        type='article'
      />
      <Box sx={{ bgcolor: 'white', maxWidth: '1024px', mx: 'auto' }}>
        <Container sx={{ paddingInline: '14px !important' }}>
          {children}
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default MainLayout;
