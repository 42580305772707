import React from 'react';
import JourneyLayout from '../layout/JourneyLayout';
import JourneyStepper2 from '../components/JourneyStepper2';

const CustomerJourney2 = () => {
  return (
    <JourneyLayout>
      <JourneyStepper2 />
    </JourneyLayout>
  );
};

export default CustomerJourney2;
