import {
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';
import { PropertyTypeEnum } from '../../utils/app.enums';

const PropertyType = () => {
  const { updateTitle } = usePageTitleStore();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    useFormInputs,
    activeStep,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
    updateFormInputs,
  } = useCustomerStore();

  const options = [
    {
      name: (
        <>
          <strong>{t('main.propertyType.options.opt1.title')}</strong>{' '}
          <span
            style={{
              fontSize: theme.typography.caption.fontSize,
            }}
          >
            {t('main.propertyType.options.opt1.subtitle')}
          </span>
        </>
      ),
      selected: false,
      value: PropertyTypeEnum.RESIDENTIAL,
    },
    {
      name: (
        <>
          <strong>{t('main.propertyType.options.opt2.title')}</strong>{' '}
          <span
            style={{
              fontSize: theme.typography.caption.fontSize,
            }}
          >
            {t('main.propertyType.options.opt2.subtitle')}
          </span>
        </>
      ),
      selected: false,
      value: PropertyTypeEnum.COMMERCIAL,
    },
  ];

  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please select an option'),
  });

  const formik = useFormik({
    initialValues: {
      selectedOption: useFormInputs['propertyDetails']['propertyType'],
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      updateFormInputs({
        ...useFormInputs,
        propertyDetails: {
          ...useFormInputs['propertyDetails'],
          propertyType: values.selectedOption,
        },
      });
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    updateTitle('Property Type | VaySolar');
    setSubmitIsClicked(false);
  }, [setSubmitIsClicked, updateTitle, useFormInputs]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      setSubmitIsClicked(false);
    }
  }, [formik, setSubmitIsClicked, submitIsClicked]);

  return (
    <Container>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.body1.fontSize,
            },
            fontWeight: 'bold',
            color: theme.palette.primary.main,
          }}
        >
          {t('main.common.feasibility')}
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.subtitle2.fontSize,
              md: theme.typography.subtitle2.fontSize,
            },
            color: theme.palette.text.tertiary,
          }}
        >
          {t('main.propertyType.subtitle')}
        </Typography>
        <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
          <Typography
            sx={{
              marginTop: '160px',
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
            }}
          >
            {t('main.propertyType.connectionType')}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: {
                xs: theme.typography.subtitle1.fontSize,
                md: theme.typography.subtitle1.fontSize,
              },
              marginBottom: '30px',
            }}
          >
            {t('main.propertyType.connectionTypeSubtitle')}
          </Typography>
        </Stack>

        <Stack
          spacing={2}
          width='100%'
          maxWidth={'600px'}
          paddingBottom={'10px'}
        >
          {options.map((option, index) => (
            <Stack key={index}>
              <Button
                variant={
                  formik.values.selectedOption === option.value
                    ? 'contained'
                    : 'text'
                }
                onClick={() => {
                  formik.setFieldValue('selectedOption', option.value);
                  formik.setFieldTouched('selectedOption');
                  setTimeout(formik.handleSubmit, 0);
                }}
                className={
                  formik.values.selectedOption === option.value
                    ? 'Mui-selected'
                    : ''
                }
                selected={formik.values.selectedOption === option.value}
                sx={{
                  border: '1px solid #A8D49959',
                  width: { xs: '100%', md: '568px' },
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: isMobile ? 'flex-start' : 'center',
                  boxShadow: '2px 2px 2px 2px #D3D3D3',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'initial',
                    gap: '8px',
                    color:
                      formik.values.selectedOption === option.value
                        ? 'white'
                        : 'black',
                  }}
                >
                  <span>{option.name}</span>
                </Typography>
              </Button>
            </Stack>
          ))}
        </Stack>
        {formik.touched.selectedOption && formik.errors.selectedOption ? (
          <Typography sx={{ color: theme.palette.error.main }}>
            {formik.errors.selectedOption}
          </Typography>
        ) : null}
      </Stack>
    </Container>
  );
};

export default PropertyType;
