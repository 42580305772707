import ApartmentIcon from '@mui/icons-material/Apartment';
import RoofingIcon from '@mui/icons-material/Roofing';
import {
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';
import PropertyDetails from '../ui/PropertyDetails';

const RoofType = () => {
  const {
    useFormInputs,
    activeStep,
    updateFormInputs,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const { updateTitle } = usePageTitleStore();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const options = [
    {
      name: t('main.roofType.options.opt1'),
      icon: <ApartmentIcon />,
      selected: false,
      value: 'Flat concrete',
    },
    {
      name: t('main.roofType.options.opt2'),
      icon: <RoofingIcon />,
      selected: false,
      value: 'Tilted or shed like',
    },
  ];
  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please select an option'),
  });
  const formik = useFormik({
    initialValues: {
      selectedOption: useFormInputs.siteVisitData.roofType,
    },
    validationSchema,
    onSubmit: (values) => {
      updateFormInputs({
        ...useFormInputs,
        siteVisitData: {
          ...useFormInputs['siteVisitData'],
          roofType: values.selectedOption.toString(),
        },
      });
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    updateTitle('Roof Type | VaySolar');
    setSubmitIsClicked(false);
  }, [setSubmitIsClicked, updateTitle, useFormInputs]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      setSubmitIsClicked(false);
    }
  }, [submitIsClicked, formik, setSubmitIsClicked]);

  return (
    <Container>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.body1.fontSize,
            },
            fontWeight: 'bold',
            color: theme.palette.primary.main,
          }}
        >
          {t('main.roofType.title')}
        </Typography>
        <PropertyDetails
          propertyDetails={useFormInputs.propertyDetails}
          siteVisitData={useFormInputs.siteVisitData}
        />
        <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
          <Typography
            sx={{
              marginTop: '100px',
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
            }}
          >
            {t('main.roofType.roofBuiltQuestion')}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: {
                xs: theme.typography.subtitle1.fontSize,
                md: theme.typography.subtitle1.fontSize,
              },
              marginBottom: '25px',
            }}
          >
            {t('main.roofType.roofBuiltOptions')}
          </Typography>
        </Stack>

        <Stack spacing={2} width='100%' maxWidth={'600px'} pb={'10px'}>
          {options.map((option, index) => (
            <Stack key={index}>
              <Button
                variant={
                  formik.values.selectedOption === option.value
                    ? 'contained'
                    : 'text'
                }
                onClick={() => {
                  formik.setFieldValue('selectedOption', option.value);
                  formik.setFieldTouched('selectedOption');
                  setTimeout(formik.handleSubmit, 0);
                }}
                className={
                  formik.values.selectedOption === option.value
                    ? 'Mui-selected'
                    : ''
                }
                selected={formik.values.selectedOption === option.value}
                sx={{
                  border: '1px solid  #A8D49959',
                  width: { xs: '100%', md: '568px' },
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: isMobile ? 'flex-start' : 'center',
                  boxShadow: '2px 2px 2px 2px #D3D3D3',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'initial',
                    gap: '8px',
                    color:
                      formik.values.selectedOption === option.value
                        ? 'white'
                        : 'black',
                  }}
                >
                  <span
                    style={{
                      color:
                        formik.values.selectedOption === option.value
                          ? 'white'
                          : 'green',
                    }}
                  >
                    {option.icon}
                  </span>
                  <span>{option.name}</span>
                </Typography>
              </Button>
            </Stack>
          ))}
        </Stack>
        {formik.touched.selectedOption && formik.errors.selectedOption ? (
          <Typography sx={{ color: theme.palette.error.main }}>
            {formik.errors.selectedOption}
          </Typography>
        ) : null}
      </Stack>
    </Container>
  );
};

export default RoofType;
