import { create } from 'zustand';

export const usePageTitleStore = create((set) => ({
  useTitle: 'Easiest way to go solar!',
  useDescription: null,
  useImage: null,
  updateTitle: (newTitle) => set(() => ({ useTitle: newTitle })),
  updateDescription: (newDescription) =>
    set(() => ({ useDescription: newDescription })),
  updateImage: (newImage) => set(() => ({ useImage: newImage })),
}));
