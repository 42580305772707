import { Apartment } from '@mui/icons-material';
import RoofingIcon from '@mui/icons-material/Roofing';
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';
import PropertyDetails from '../ui/PropertyDetails';

const RoofTopAreaV2 = () => {
  const {
    useFormInputs,
    activeStep,
    updateFormInputs,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const { updateTitle } = usePageTitleStore();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const validationSchema = Yup.object({
    roofTopArea: Yup.number()
      .positive('Roof Top Area must be a positive number')
      .min(1, 'Roof Top Area must be at least 1')
      .required('Roof Top Area is required')
      .typeError('Roof Top Area must be a number'),
    floor: Yup.number()
      .positive('Floor must be a positive number')
      .min(1, 'Floor must be at least 1')
      .max(100, 'Floor cannot be more than 100')
      .required('Floor is required')
      .typeError('Floor must be a number'),
  });

  const formik = useFormik({
    initialValues: {
      roofTopArea: useFormInputs.siteVisitData.roofTopArea || '',
      floor: useFormInputs.siteVisitData.floor || '',
    },
    validationSchema,
    onSubmit: (values) => {
      updateFormInputs({
        ...useFormInputs,
        propertyDetails: {
          ...useFormInputs['propertyDetails'],
          shadowFreeArea: values.roofTopArea,
        },
        siteVisitData: {
          ...useFormInputs['siteVisitData'],
          roofTopArea: values.roofTopArea,
          floor: values.floor,
        },
      });
      setActiveStep(activeStep + 1);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    updateTitle('Roof Top Area | VaySolar');
    setSubmitIsClicked(false);
  }, [setSubmitIsClicked, updateTitle, useFormInputs]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      setSubmitIsClicked(false);
    }
  }, [submitIsClicked, formik, setSubmitIsClicked]);

  return (
    <Container>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.body1.fontSize,
            },
            fontWeight: 'bold',
            color: theme.palette.primary.main,
          }}
        >
          {t('main.roofType.title')}
        </Typography>
        <PropertyDetails
          propertyDetails={useFormInputs.propertyDetails}
          siteVisitData={useFormInputs.siteVisitData}
        />
        <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
          <Typography
            sx={{
              marginTop: '100px',
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
              mb: '25px',
            }}
          >
            {t('main.roofTop.title.text1')}
            <b>{t('main.roofTop.title.text2')}</b>
          </Typography>
        </Stack>
        <Box width='100%' maxWidth={'600px'}>
          <Stack direction='row' alignItems='flex-end' spacing={2}>
            <RoofingIcon color='primary' />
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('main.roofTop.placeholder1')}</InputLabel>
              <Select
                name='roofTopArea'
                value={formik.values.roofTopArea}
                onChange={formik.handleChange}
              >
                {[...Array(20).keys()].map((_, index) => {
                  const value = (index + 1) * 100;
                  return (
                    <MenuItem value={value} key={index}>
                      {value} sqft
                    </MenuItem>
                  );
                })}
                <MenuItem value={2100}>2000+ sqft</MenuItem>
              </Select>
              {formik.touched.roofTopArea && formik.errors.roofTopArea && (
                <FormHelperText error>
                  {formik.errors.roofTopArea}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>

          <Stack
            direction='row'
            alignItems='flex-end'
            spacing={2}
            marginTop={4}
          >
            <Apartment color='primary' />
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('main.roofTop.placeholder2')}</InputLabel>
              <Select
                name='floor'
                value={formik.values.floor}
                onChange={formik.handleChange}
              >
                {[...Array(20).keys()].map((_, index) => {
                  const value = index + 1;
                  return (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  );
                })}
                <MenuItem value={21}>20+ sqft</MenuItem>
              </Select>
              {formik.touched.floor && formik.errors.floor && (
                <FormHelperText error>{formik.errors.floor}</FormHelperText>
              )}
            </FormControl>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default RoofTopAreaV2;
