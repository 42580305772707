import { Place } from '@mui/icons-material';
import {
  Box,
  Container,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import location from '../../Image/location.svg';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';

const PinCodeApi = 'https://api.postalpincode.in/pincode';

const SolarRoofFinderV2 = () => {
  const { updateTitle } = usePageTitleStore();
  const {
    useFormInputs,
    activeStep,
    updateFormInputs,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const theme = useTheme();
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    pinCode: Yup.string()
      .matches(/^\d+$/, t('main.findRoof.input.matches'))
      .length(6, t('main.findRoof.input.length'))
      .required(t('main.findRoof.input.required')),
  });

  const formik = useFormik({
    initialValues: {
      pinCode: useFormInputs['propertyDetails']['pinCode'],
    },
    validationSchema,
    validateOnMount: true,
    validate: async (values) => {
      const errors = {};
      if (Number(values.pinCode) && values.pinCode.length === 6) {
        try {
          const response = await axios.get(`${PinCodeApi}/${values.pinCode}`);
          const data = response.data;
          if (data[0].Status === 'Error') {
            errors.pinCode = t('main.findRoof.input.valid');
          } else {
            updateFormInputs({
              ...useFormInputs,
              propertyDetails: {
                ...useFormInputs['propertyDetails'],
                address: {
                  ...useFormInputs['propertyDetails']['address'],
                  state: data[0].PostOffice[0].State,
                },
              },
            });
          }
        } catch (error) {
          errors.pinCode = t('main.findRoof.input.api');
        }
      }
      return errors;
    },
    onSubmit: (values) => {
      updateFormInputs({
        ...useFormInputs,
        propertyDetails: {
          ...useFormInputs['propertyDetails'],
          pinCode: values.pinCode,
          address: {
            ...useFormInputs['propertyDetails']['address'],
            pinCode: values.pinCode,
          },
        },
      });
      setActiveStep(activeStep + 1);
    },
    enableReinitialize: true,
  });

  const handlePinCodeChange = (val) => {
    formik.setFieldTouched('pinCode');
    formik.setFieldValue('pinCode', val);
  };

  useEffect(() => {
    updateTitle('Roof finder | VaySolar');
    setSubmitIsClicked(false);
  }, [setSubmitIsClicked, updateTitle, useFormInputs]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      setSubmitIsClicked(false);
    }
  }, [formik, setSubmitIsClicked, submitIsClicked]);

  return (
    <Container>
      <Stack spacing={3} alignItems={'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h6.fontSize,
              md: theme.typography.h4.fontSize,
            },
            fontWeight: 'bold',
          }}
        >
          {t('main.findRoof.title1')}
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            display: { sm: 'flex', xs: 'none' },
          }}
        >
          <img
            alt='Location'
            src={location}
            style={{
              display: 'inline-block',
              maxWidth: '100%',
              width: 300,
              opacity: 0.5,
            }}
          />
        </Box>
        <Stack width='100%' maxWidth={'600px'} pt={{ sm: '0px', xs: '60%' }}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <span
              style={{
                color: theme.palette.primary.main,
              }}
            >
              <Place />
            </span>
            <TextField
              label={t('main.findRoof.input.label1_v2')}
              variant='standard'
              fullWidth
              name='pinCode'
              type='tel'
              inputMode='numeric'
              value={formik.values.pinCode}
              onChange={(e) => handlePinCodeChange(e.target.value)}
              helperText={t('main.findRoof.input.subtitle')}
            />
          </Stack>
          {formik.touched.pinCode && formik.errors.pinCode && (
            <Typography
              sx={{
                color: theme.palette.error.main,
                fontSize: theme.typography.subtitle2.fontSize,
              }}
            >
              {formik.errors.pinCode}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default SolarRoofFinderV2;
