import ApartmentIcon from '@mui/icons-material/Apartment';
import HelpIcon from '@mui/icons-material/Help';
import RoofingIcon from '@mui/icons-material/Roofing';
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import faqImage from '../../Image/faq.svg';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';
import tickLogo from '../animate/static/tick-mark.json';
import { showAnimate } from '../constant';
import InformationModal from '../ui/InformationModal';

const SiteAssessmentType = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const {
    activeStep,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
    setIsStepperHidden,
  } = useCustomerStore();
  const { updateTitle } = usePageTitleStore();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const options = [
    {
      name: (
        <>
          {t('main.siteAssessment.options.opt1.title')}{' '}
          <span
            style={{
              fontSize: theme.typography.caption.fontSize,
            }}
          >
            {t('main.siteAssessment.options.opt1.subtitle')}
          </span>
        </>
      ),
      icon: <ApartmentIcon />,
      selected: false,
      value: 'SELF_ASSESS',
    },
    {
      name: (
        <>
          {t('main.siteAssessment.options.opt2.title')}{' '}
          <span
            style={{
              fontSize: theme.typography.caption.fontSize,
            }}
          >
            {t('main.siteAssessment.options.opt2.subtitle')}
          </span>
        </>
      ),
      icon: <RoofingIcon />,
      selected: false,
      value: 'SITE_VISIT',
    },
  ];
  const formik = useFormik({
    initialValues: {
      selectedOption: '',
    },
    onSubmit: (values) => {
      if (values.selectedOption === 'SELF_ASSESS') {
        setTimeout(() => setActiveStep(activeStep + 1), 500);
      } else if (values.selectedOption === 'SITE_VISIT') {
        setTimeout(() => navigate('/dashboard'), 500);
      }
    },
    enableReinitialize: true,
  });

  const animationPlayed = useRef(false);
  const [contentVisible, setContentVisible] = useState(false); //Content visibility after animation

  useEffect(() => {
    updateTitle('Gather Site Information | VaySolar');
  }, [updateTitle]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      setSubmitIsClicked(false);
    }
  }, [submitIsClicked, formik, setSubmitIsClicked]);

  // useEffect(() => {
  //   formik.setFieldValue('selectedOption', 'SELF_ASSESS');
  //   formik.handleSubmit();
  // }, [])

  useEffect(() => {
    if (!animationPlayed.current) {
      setIsStepperHidden(true);
      showAnimate(tickLogo, '#tick-logo');
      animationPlayed.current = true;
    }
    const timer = setTimeout(() => {
      setContentVisible(true);
      setIsStepperHidden(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [setIsStepperHidden]);

  return (
    <Container>
      <style>
        {`
          @keyframes pulsateBorder {
            0% {
              border-color: #A8D49959;
              box-shadow: 0 0 0 0 rgba(168, 212, 153, 0.5);
            }
            50% {
              border-color: #A8D499;
              box-shadow: 0 0 10px 10px rgba(168, 212, 153, 0);
            }
            100% {
              border-color: #A8D49959;
              box-shadow: 0 0 0 0 rgba(168, 212, 153, 0.5);
            }
          }
        `}
      </style>
      <Stack spacing={2} alignItems={isMobile ? 'flex-start' : 'center'}>
        <Box
          width={240}
          id='tick-logo'
          sx={{
            alignSelf: 'center',
          }}
        />
        <Stack
          alignItems={isMobile ? 'flex-start' : 'center'}
          sx={{
            opacity: contentVisible ? 1 : 0,
            transition: 'opacity 1.5s ease-in',
          }}
        >
          <Typography
            sx={{
              marginTop: '10px',
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
            }}
          >
            {t('main.siteAssessment.title4')}{' '}
            <strong>{t('main.siteAssessment.title5')}</strong>
            <IconButton aria-label='delete' onClick={() => handleModalOpen()}>
              <HelpIcon sx={{ color: theme.palette.black }} />
              <InformationModal open={openModal} onClose={handleModalClose}>
                <ModalBody />
              </InformationModal>
            </IconButton>
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: {
                xs: theme.typography.subtitle2.fontSize,
                md: theme.typography.subtitle2.fontSize,
              },
              marginBottom: '15px',
            }}
          >
            {t('main.siteAssessment.subtitle3')}
          </Typography>
        </Stack>

        <Stack
          spacing={2}
          width='100%'
          maxWidth={'600px'}
          pb={'10px'}
          sx={{
            opacity: contentVisible ? 1 : 0,
            transition: 'opacity 1.5s ease-in',
          }}
        >
          {options.map((option, index) => (
            <Stack key={index}>
              <Button
                variant={
                  formik.values.selectedOption === option.value
                    ? 'contained'
                    : 'text'
                }
                onClick={() => {
                  formik.setFieldValue('selectedOption', option.value);
                  formik.handleSubmit();
                }}
                className={
                  formik.values.selectedOption === option.value
                    ? 'Mui-selected'
                    : ''
                }
                selected={formik.values.selectedOption === option.value}
                sx={{
                  border: '1px solid  #A8D49959',
                  width: { xs: '100%', md: '568px' },
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: isMobile ? 'flex-start' : 'center',
                  marginRight: '40px',
                  boxShadow: '2px 2px 2px 2px #D3D3D3',
                  animation: index === 0 ? 'pulsateBorder 2s infinite' : 'none',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'initial',
                    gap: '8px',
                    color:
                      formik.values.selectedOption === option.value
                        ? 'white'
                        : 'black',
                  }}
                >
                  <span
                    style={{
                      color:
                        formik.values.selectedOption === option.value
                          ? 'white'
                          : 'green',
                    }}
                  >
                    {option.icon}
                  </span>
                  <span>{option.name}</span>
                </Typography>
              </Button>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};

const ModalBody = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Stack direction='column'>
        <Typography
          id='modal-modal-title'
          variant='h5'
          component='h2'
          sx={{ marginBottom: '30px' }}
        >
          {t('main.siteAssessment.informationModal.title1')}
        </Typography>
        <Typography
          id='modal-modal-description'
          variant='subtitle1'
          sx={{
            mt: 2,
            color: theme.palette.secondary.light,
            marginTop: '0px',
            marginBottom: '20px',
          }}
        >
          {t('main.siteAssessment.informationModal.text1')}
        </Typography>
        <img
          alt=''
          src={faqImage}
          style={{
            display: 'inline-block',
            maxWidth: '100%',
            width: '300px',
            opacity: '0.8',
            marginTop: '40px',
          }}
        ></img>
      </Stack>
    </>
  );
};

export default SiteAssessmentType;
