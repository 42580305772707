import { Engineering, Security } from '@mui/icons-material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import readingImage from '../../Image/readingList.svg';
import InformationModal from './InformationModal';

function Features({ icon, subtitle, children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        justifyContent: isMobile ? 'flex-start' : 'center',
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        spacing={1}
        style={{
          marginTop: '20px',
        }}
      >
        <Box style={{ height: '2em' }}> {icon}</Box>
        <Box>
          {' '}
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.subtitle1.fontSize,
                md: theme.typography.h5.fontSize,
              },
              textAlign: 'left',
            }}
          >
            {children}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.subtitle2.fontSize,
                md: theme.typography.h6.fontSize,
              },
              marginTop: '0px',
              textAlign: 'left',
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );
}

const SolarPlantRecommendation = ({ recommendation }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <Container>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
        {recommendation.load &&
          recommendation.plantType &&
          recommendation.structure && (
            <>
              <Typography
                sx={{
                  fontSize: theme.typography.subtitle1.fontSize,
                  color: theme.palette.text.secondary,
                  marginTop: '10px',
                }}
              >
                {t('main.solarPlantRecommendation.title')}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: theme.typography.h6.fontSize,
                    md: theme.typography.h5.fontSize,
                  },
                }}
              >
                <strong>
                  {' '}
                  {recommendation.load} {recommendation.plantType}{' '}
                </strong>
                {t('main.solarPlantRecommendation.solarPlantWith')}{' '}
                <strong> {recommendation.structure}</strong>
                <IconButton aria-label='delete'>
                  <HelpIcon
                    onClick={() => handleModalOpen()}
                    sx={{ color: theme.palette.black }}
                  />
                </IconButton>
                <InformationModal open={openModal} onClose={handleModalClose}>
                  <ModalBody />
                </InformationModal>
              </Typography>
            </>
          )}
        <Stack marginTop={'20px'} alignItems={'flex-start'} spacing={1}>
          <Features
            icon={
              <OpenInFullIcon
                fontSize='large'
                style={{ color: theme.palette.primary.main }}
              />
            }
            subtitle={t('main.solarPlantRecommendation.features.feature1.text')}
          >
            <Typography
              fontWeight='bold'
              sx={{
                fontSize: {
                  xs: theme.typography.subtitle1.fontSize,
                  md: theme.typography.h5.fontSize,
                },
              }}
            >
              <span style={{ color: theme.palette.primary.main }}>
                {t('main.solarPlantRecommendation.features.feature1.title1')}
              </span>{' '}
              {t('main.solarPlantRecommendation.features.feature1.title2')}
            </Typography>
          </Features>
          <Features
            icon={
              <Engineering
                fontSize='large'
                style={{ color: theme.palette.primary.main }}
              />
            }
            subtitle={t('main.solarPlantRecommendation.features.feature2.text')}
          >
            <Typography
              fontWeight='bold'
              sx={{
                fontSize: {
                  xs: theme.typography.subtitle1.fontSize,
                  md: theme.typography.h5.fontSize,
                },
              }}
            >
              {t('main.solarPlantRecommendation.features.feature2.title1')}{' '}
              <span style={{ color: theme.palette.primary.main }}>
                {t('main.solarPlantRecommendation.features.feature2.title2')}
              </span>{' '}
              {t('main.solarPlantRecommendation.features.feature2.title3')}
            </Typography>
          </Features>
          <Features
            icon={
              <Security
                fontSize='large'
                style={{ color: theme.palette.primary.main }}
              />
            }
            subtitle={t('main.solarPlantRecommendation.features.feature3.text')}
          >
            <Typography
              fontWeight='bold'
              sx={{
                fontSize: {
                  xs: theme.typography.subtitle1.fontSize,
                  md: theme.typography.h5.fontSize,
                },
              }}
            >
              <span style={{ color: theme.palette.primary.main }}>
                {t('main.solarPlantRecommendation.features.feature3.title1')}
              </span>{' '}
              {t('main.solarPlantRecommendation.features.feature3.title2')}
            </Typography>
          </Features>
        </Stack>
      </Stack>
    </Container>
  );
};

const ModalBody = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Stack direction='column'>
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h2'
          sx={{ marginBottom: '30px' }}
        >
          {t('main.solarPlantRecommendation.informationModal.title1')}
        </Typography>
        <Typography
          id='modal-modal-title'
          variant='subtitle1'
          component='h2'
          sx={{ marginBottom: '10px', color: theme.palette.secondary.light }}
        >
          {t('main.solarPlantRecommendation.informationModal.text1Heading')}
        </Typography>
        <Typography
          id='modal-modal-description'
          variant='subtitle2'
          sx={{
            mt: 2,
            color: theme.palette.secondary.light,
            marginTop: '0px',
            marginBottom: '20px',
          }}
        >
          {t('main.solarPlantRecommendation.informationModal.text1')}
        </Typography>
        <Typography
          id='modal-modal-title'
          variant='subtitle1'
          component='h2'
          sx={{ marginBottom: '10px', color: theme.palette.secondary.light }}
        >
          {t('main.solarPlantRecommendation.informationModal.text2Heading')}
        </Typography>
        <Typography
          id='modal-modal-description'
          variant='subtitle2'
          sx={{
            mt: 2,
            color: theme.palette.secondary.light,
            marginTop: '0px',
            marginBottom: '20px',
          }}
        >
          {t('main.solarPlantRecommendation.informationModal.text2')}
        </Typography>
        <Typography
          id='modal-modal-title'
          variant='subtitle1'
          component='h2'
          sx={{ marginBottom: '10px', color: theme.palette.secondary.light }}
        >
          {t('main.solarPlantRecommendation.informationModal.text3Heading')}
        </Typography>
        <Typography
          id='modal-modal-description'
          variant='subtitle2'
          sx={{
            mt: 2,
            color: theme.palette.secondary.light,
            marginTop: '0px',
            marginBottom: '10px',
          }}
        >
          {t('main.solarPlantRecommendation.informationModal.text3')}
        </Typography>
        <img
          src={readingImage}
          style={{
            display: 'inline-block',
            maxWidth: '100%',
            width: 150,
            opacity: '0.8',
            marginTop: '40px',
          }}
        ></img>
      </Stack>
    </>
  );
};

export default SolarPlantRecommendation;
