import {
  Container,
  InputAdornment,
  Slider,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';
import PropertyDetails from '../ui/PropertyDetails';

const months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

const ElectricityBillV2 = () => {
  const {
    useFormInputs,
    activeStep,
    updateFormInputs,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const { updateTitle } = usePageTitleStore();
  const [value, setValue] = useState(
    Number(useFormInputs['propertyDetails']['billAmount']),
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const validationSchema = yup.object({
    sliderInput: yup.number().max(50000, t('main.billSelect.slider.error')),
  });

  const formik = useFormik({
    initialValues: {
      sliderInput: value,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      const currDate = new Date();
      updateFormInputs({
        ...useFormInputs,
        propertyDetails: {
          ...useFormInputs['propertyDetails'],
          billAmount: values.sliderInput.toString(),
          billMonth:
            months[
              new Date(currDate.setMonth(currDate.getMonth() - 1)).getMonth()
            ],
        },
      });
      setActiveStep(activeStep + 1);
    },
    enableReinitialize: true,
  });

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    if (event.target.value <= 50000 && event.target.value >= 0) {
      setValue(event.target.value === '' ? 0 : Number(event.target.value));
    }
    if (event.target.value > 50000) {
      toast.error(t('main.billSelect.slider.error'));
    }
  };

  useEffect(() => {
    updateTitle('Electricity bill | VaySolar');
    setSubmitIsClicked(false);
  }, [setSubmitIsClicked, updateTitle, useFormInputs]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.setFieldValue('sliderInput', value);
      formik.handleSubmit();
      setSubmitIsClicked(false);
    }
  }, [formik, setSubmitIsClicked, submitIsClicked, value]);

  return (
    <Container>
      <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.body1.fontSize,
              md: theme.typography.body1.fontSize,
            },
            fontWeight: 'bold',
            color: theme.palette.primary.main,
          }}
        >
          {t('main.common.feasibility')}
        </Typography>
        <PropertyDetails
          propertyDetails={useFormInputs.propertyDetails}
          siteVisitData={useFormInputs.siteVisitData}
        />
        <Stack alignItems={isMobile ? 'flex-start' : 'center'}>
          <Typography
            sx={{
              marginTop: '100px',
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
            }}
          >
            {t('main.billSelect.title')}
          </Typography>
          <Typography
            sx={{
              marginBottom: '50px',
              fontSize: {
                xs: theme.typography.subtitle1.fontSize,
                md: theme.typography.subtitle1.fontSize,
              },
              color: theme.palette.text.secondary,
            }}
          >
            {t('main.billSelect.subtitle')}
          </Typography>
        </Stack>
        <Stack spacing={1.5} px={0.5} width='100%' maxWidth={'600px'} pb={3}>
          <Slider
            value={value}
            max={50000}
            min={0}
            valueLabelDisplay='on'
            step={500}
            marks
            sx={{
              '& .MuiSlider-thumb': {
                height: 34,
                width: 34,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
              },
              '& .MuiSlider-track': {
                height: 6,
              },
              '& .MuiSlider-rail': {
                height: 6,
              },
            }}
            onChange={handleSliderChange}
          />
          <TextField
            sx={{
              width: { xs: '150px', md: '300px' },
              display: 'none',
            }}
            color='success'
            name='sliderInput'
            value={value}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Typography variant='body1' color='textSecondary'>
                    ₹
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default ElectricityBillV2;
