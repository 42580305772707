import { Call } from '@mui/icons-material';
import {
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { checkPhoneNumber } from '../../services/CustomerService';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';

const ContactV2 = () => {
  const { updateTitle } = usePageTitleStore();
  const {
    useFormInputs,
    activeStep,
    updateFormInputs,
    setActiveStep,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const validationSchema = yup.object({
    phoneNumber: yup
      .string()
      .matches(/^\d{10}$/, t('main.contactYou.input.errorPhone'))
      .required(t('main.contactYou.input.errorType')),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: useFormInputs['phoneNumber'] || '',
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        const response = await checkPhoneNumber(values.phoneNumber);
        if (response.exists === true) {
          formik.setFieldError('phoneNumber', 'Phone number already exists');
        } else {
          updateFormInputs({
            ...useFormInputs,
            phoneNumber: values.phoneNumber,
            prefCommChannel: 'CALL',
            propertyDetails: {
              ...useFormInputs['propertyDetails'],
            },
          });
          setActiveStep(activeStep + 1);
        }
      } catch (error) {
        formik.setFieldError(
          'phoneNumber',
          'Something went wrong. Please try again.',
        );
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    updateTitle('Contact Details | VaySolar');
  }, [updateTitle]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      if (formik.errors.phoneNumber) {
        formik.setFieldError('phoneNumber', formik.errors.phoneNumber);
      }
      setSubmitIsClicked(false);
    }
  }, [formik, submitIsClicked]);

  return (
    <Container>
      <Stack spacing={1} alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
              md: theme.typography.h4.fontSize,
            },
            fontWeight: 'bold',
          }}
        >
          {t('main.contactYou.title2.text1')}{' '}
          <span style={{ color: theme.palette.golden }}>
            {t('main.contactYou.title2.text2')}
          </span>{' '}
          {t('main.contactYou.title2.text3')}{' '}
        </Typography>

        <Stack spacing={4} width='100%' maxWidth={'600px'}>
          <Stack>
            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              marginTop={10}
            >
              <span
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                <Call />
              </span>
              <TextField
                label={t('main.contactYou.input.placeholder1')}
                variant='standard'
                name='phoneNumber'
                color='success'
                inputMode='numeric'
                type='tel'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>+91</InputAdornment>
                  ),
                }}
                fullWidth
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                helperText={t('main.contactYou.input.subtitle')}
              />
            </Stack>
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <Typography
                sx={{ color: theme.palette.error.main, marginLeft: '2rem' }}
              >
                {formik.errors.phoneNumber}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ContactV2;
