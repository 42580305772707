import lottie from 'lottie-web/build/player/lottie_light';

export function showAnimate(animData, animContainer) {
  lottie.loadAnimation({
    container: document.querySelector(animContainer),
    animationData: animData,
    renderer: 'svg', // "canvas", "html"
    loop: false, // boolean
    autoplay: true, // boolean
  });
}

export const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};
