import React from 'react';
import { Navigate } from 'react-router-dom';

function RouteGuard({ children }) {
  const hasJWT = () => {
    return localStorage.getItem('accessToken') ? true : false;
  };

  return hasJWT() ? children : <Navigate to={{ pathname: '/' }} replace />;
}

export default RouteGuard;
