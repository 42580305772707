import { Security } from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GroupIcon from '@mui/icons-material/Group';
import LightModeIcon from '@mui/icons-material/LightMode';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import { List, ListItem, Typography, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { calculateYears, formatDate, toSentenceCase } from '../../utils/utils';

function VendorSummary({ vendor }) {
  const yearsSinceEstablishment = calculateYears(vendor?.establishmentDate);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const vendorFeatures = [
    // vendor?.documents?.reference.length > 0 && {
    //   icon: <EngineeringIcon />,
    //   title: `${vendor.documents.reference.length} verified installations`,
    // },
    // retaining code in case need to add back

    // vendor?.documents?.identity.length > 0 && {
    //   icon: <Security />,
    //   title: `${vendor.documents.identity.length} verified identity documents`,
    // },
    vendor?.experienceInMW && {
      icon: <LightModeIcon />,
      title: `${vendor.experienceInMW} MW installation in ${yearsSinceEstablishment} years`,
    },
    vendor?.establishmentDate && {
      icon: <DateRangeIcon />,
      title: `Established on ${formatDate(vendor.establishmentDate)}`,
    },
    vendor?.vendorSegments &&
      vendor.vendorSegments.length > 0 && {
        icon: <GroupIcon />,
        title: vendor.vendorSegments.map(toSentenceCase).join(' • '),
      },
    vendor?.vendorServices &&
      vendor.vendorServices.length > 0 && {
        icon: <SettingsIcon />,
        title: vendor.vendorServices.map(toSentenceCase).join(' • '),
      },
    vendor?.address && {
      icon: <LocationOnIcon />,
      title: (
        <span>
          {' '}
          Presence in{' '}
          {vendor.address.district && `${vendor.address.district}, `}
          {vendor.address.state}
        </span>
      ),
    },
  ].filter(Boolean);

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <List>
        {vendorFeatures.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              padding: { sm: '5px 0px', xs: '4px 0px' },
              color: 'primary.main',
              alignItems: 'flex-start',
            }}
          >
            {item.icon}
            <Typography
              variant='body2'
              sx={{
                paddingLeft: '24px',
                color: 'text.primary',
                fontWeight: !isMobile ? 'bold' : '',
              }}
            >
              {item.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

export default VendorSummary;
