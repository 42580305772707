import { useEffect } from 'react';
import JourneyLayout from '../layout/JourneyLayout';
import { useCustomerStore } from '../store/CustomerJourneyStore';
import { usePageTitleStore } from '../store/PageTitleStore';
import CongratulationsScreen from '../components/ui/Congratulations';

const CongratsPage = () => {
  const { resetFormInputs } = useCustomerStore();
  const { updateTitle } = usePageTitleStore();

  useEffect(() => {
    updateTitle('Congratulations | VaySolar');
    resetFormInputs();
  }, [updateTitle, resetFormInputs]);

  return (
    <JourneyLayout>
      <CongratulationsScreen />
    </JourneyLayout>
  );
};
export default CongratsPage;
