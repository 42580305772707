import { useTheme } from '@emotion/react';
import {
  Add,
  CurrencyRupeeRounded,
  Edit,
  PlaylistAddCheck,
  Visibility,
} from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
  updatePropertyVerificationStatus,
  uploadElectricityBill,
} from '../../services/CustomerService';
import { VerificationStatus } from '../../utils/app.enums';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

export default function VerifyingStep({
  propertyId,
  verificationStatus = VerificationStatus.PENDING,
  sanctionedLoad = 3,
  electricityBillUrl,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [status, setStatus] = useState(verificationStatus);
  const fileInputRef = useRef(null);
  const [electricityBillFile, setElectricityBillFile] = useState(null);
  const [fileSelectionError, setFileSelectionError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const reApplyForVerification = async () => {
    try {
      const updatedStatus = await updatePropertyVerificationStatus(
        propertyId,
        VerificationStatus.PENDING,
      );
      setStatus(updatedStatus.verificationStatus);
    } catch (error) {}
  };

  const isPendingText = (
    <Stack direction={'row'} gap={1}>
      <span
        style={{
          color: theme.palette.warning.secondary,
        }}
      >
        <AccessTimeIcon />
      </span>
      <Typography>Takes up to 48 hours</Typography>
    </Stack>
  );
  const isRejectedText = (
    <>
      <Stack direction={'row'} gap={1}>
        <span
          style={{
            color: theme.palette.error.main,
          }}
        >
          <CancelIcon />
        </span>
        <Typography>Property details have been rejected</Typography>
      </Stack>
      <Button
        variant='outlined'
        color='primary'
        sx={{
          textTransform: 'none',
          width: isMobile ? '100%' : 'auto',
          marginLeft: isMobile ? '17px' : '25px',
        }}
        onClick={reApplyForVerification}
      >
        Re-apply for verification
      </Button>
    </>
  );
  const isVerifiedText = (
    <Stack direction={'row'} gap={1} color={theme.palette.primary.main}>
      <PlaylistAddCheck />
      <Typography>{sanctionedLoad}kW plant </Typography>
    </Stack>
  );

  const validFileSize = (electricityBillFile) => {
    if (electricityBillFile.size > 5 * 1024 * 1024) {
      setFileSelectionError('File exceeds 5 MB size limit');
      return false;
    }
    return true;
  };

  const handleUploadButton = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setFileSelectionError('');
    setElectricityBillFile(null);
    const file = event.target.files[0];
    if (file && validFileSize(file)) {
      const formData = new FormData();
      formData.append('file', file);
      setElectricityBillFile(file);
    }
  };

  const handleUploadClick = async () => {
    try {
      setIsLoading(true);
      await uploadElectricityBill(propertyId, electricityBillFile);
      window.location.reload();
    } catch (error) {
      setFileSelectionError('Error uploading file');
      setElectricityBillFile(null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (electricityBillFile) {
      handleUploadClick();
    }
  }, [electricityBillFile]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const isPdf = electricityBillUrl && electricityBillUrl.includes('.pdf');

  const previewDialog = (
    <Dialog
      open={isModalOpen}
      onClose={handleModalClose}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>Electricity Bill</DialogTitle>
      <DialogContent>
        {isPdf ? (
          <Document file={electricityBillUrl}>
            <Page pageNumber={1} />
          </Document>
        ) : (
          <img
            src={electricityBillUrl}
            alt='Electricity bill'
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );

  return (
    <Box sx={{ marginLeft: isMobile ? '17px' : '25px' }}>
      {status === VerificationStatus.PENDING
        ? isPendingText
        : status === VerificationStatus.REJECTED
          ? isRejectedText
          : status === VerificationStatus.VERIFIED
            ? isVerifiedText
            : null}

      <Stack mt={2}>
        {isLoading ? (
          <Box
            sx={{
              width: '100px',
              height: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : electricityBillUrl ? (
          <>
            {isPdf ? (
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid black',
                  cursor: 'pointer',
                }}
                onClick={handleModalOpen}
              >
                <Document file={electricityBillUrl}>
                  <Page pageNumber={1} width={100} />
                </Document>
              </div>
            ) : (
              <img
                src={electricityBillUrl}
                alt='Electricity bill'
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                onClick={handleModalOpen}
              />
            )}
          </>
        ) : (
          <IconButton
            onClick={handleUploadButton}
            sx={{
              width: 'fit-content',
            }}
          >
            <CurrencyRupeeRounded
              sx={{
                fontSize: '4rem',
                color: 'white',
                bgcolor: theme.palette.gray,
                borderRadius: '50%',
                padding: '0.5rem',
                opacity: '0.5',
              }}
            />
          </IconButton>
        )}
        <Typography variant='caption' color='error'>
          {fileSelectionError}
        </Typography>
        <Stack direction={'row'} gap={0.5} alignItems={'center'} mt={2}>
          <input
            name='electricityBill'
            type='file'
            accept='.jpg, .jpeg, .png, .pdf,'
            hidden
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          {electricityBillUrl && !isLoading ? (
            <>
              <IconButton onClick={handleModalOpen}>
                <Visibility sx={{ color: 'black' }} />
              </IconButton>
              <IconButton onClick={handleUploadButton}>
                <Edit sx={{ color: 'black' }} />
              </IconButton>
            </>
          ) : (
            !isLoading && (
              <Button
                variant='text'
                color='primary'
                onClick={handleUploadButton}
                startIcon={<Add />}
              >
                Add
              </Button>
            )
          )}
        </Stack>
        <Typography
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          Electricity bill
        </Typography>
      </Stack>
      {previewDialog}
    </Box>
  );
}
