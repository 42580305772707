import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, useMediaQuery } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { addSiteVisitDetails } from '../services/CustomerService';
import { useCustomerStore } from '../store/CustomerJourneyStore';
import tickLogo from './animate/static/tick-mark.json';
import { showAnimate } from './constant';
import PlantRecommendation from './journey2/PlantRecommendation';
import PowerCuts from './journey2/PowerCuts';
import RoofTopAreaV2 from './journey2/RoofTopAreaV2';
import RoofType from './journey2/RoofType';
import RoofUsage from './journey2/RoofUsage';
import SiteAssessmentType from './journey2/SiteAssessmentType';

export default function JourneyStepper2() {
  const theme = useTheme();
  const [toggle, setToggle] = React.useState('flex');
  const navigate = useNavigate();
  const location = useLocation();
  const [cstmId, setCstmId] = React.useState(null);
  const {
    activeStep,
    setActiveStep,
    setSubmitIsClicked,
    useFormInputs,
    setFinalFlag,
    finalFlag,
    isStepperHidden,
    setIsStepperHidden,
  } = useCustomerStore();
  const { t } = useTranslation();
  const maxSteps = 6;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const steps = [
    SiteAssessmentType,
    RoofType,
    RoofTopAreaV2,
    PowerCuts,
    RoofUsage,
    PlantRecommendation,
  ];

  const sendData = async () => {
    if (useFormInputs.propertyDetails.id === null && cstmId === null) {
      return;
    }
    const useId = useFormInputs.propertyDetails.id || cstmId;
    await addSiteVisitDetails(useFormInputs.siteVisitData, useId);
    setToggle('none');
    showAnimate(tickLogo, '#tick-logo');
    setTimeout(() => {
      navigate('/dashboard');
      setToggle('flex');
      setFinalFlag(false);
      setActiveStep(0);
      setSubmitIsClicked(false);
    }, 4000);
  };

  const handleNext = () => {
    setSubmitIsClicked(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (activeStep === maxSteps - 1) {
      sendData();
    }
  };

  const handleBack = () => {
    setSubmitIsClicked(false);
    setActiveStep(activeStep - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // React.useEffect(() => {
  //   if (finalFlag && activeStep === maxSteps - 1) {
  //     handleNext();
  //   }
  // }, [finalFlag]);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.size) {
      const customStep = Number(params.get('step'));
      const customId = Number(params.get('id'));
      if (customStep) {
        setActiveStep(customStep);
        setIsStepperHidden(false);
      }
      setCstmId(customId);
    }
  }, []);

  const CurrentStepComponent = activeStep < maxSteps ? steps[activeStep] : null;

  return (
    <>
      <Box>
        <Box
          sx={{
            height: { sm: '100%', xs: '100vh' },
          }}
        >
          {CurrentStepComponent ? <CurrentStepComponent /> : null}
        </Box>
        <Box
          position='absolute'
          top={0}
          right={0}
          bottom={0}
          left={0}
          sx={{
            display: toggle === 'flex' ? 'none' : 'grid',
            placeContent: 'center',
          }}
        >
          <Box width={{ md: 440, xs: 240 }} id='tick-logo' />
        </Box>

        {finalFlag
          ? ''
          : !isStepperHidden && (
              <MobileStepper
                variant='progress'
                steps={maxSteps}
                position={isMobile ? 'bottom' : 'static'}
                activeStep={activeStep}
                sx={{
                  py: '28px',
                }}
                nextButton={
                  <Button
                    variant='contained'
                    disableElevation
                    size={isMobile ? 'small' : 'large'}
                    onClick={handleNext}
                  >
                    {activeStep === 5
                      ? t('main.buttons.finish')
                      : t('main.buttons.next')}
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size={isMobile ? 'small' : 'large'}
                    variant='text'
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    {t('main.buttons.back')}
                  </Button>
                }
              />
            )}
      </Box>
    </>
  );
}
