import {
  Button,
  Container,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';
import { PowerCutFrequency, RoofUsageFrequency } from '../../utils/app.enums';
import { Sell } from '@mui/icons-material';
import SolarPlantRecommendation from '../ui/SolarPlantRecommendation';

const PlantRecommendation = () => {
  const {
    useFormInputs,
    setSubmitIsClicked,
    setFinalFlag,
    setActiveStep,
    activeStep,
  } = useCustomerStore();
  const { updateTitle } = usePageTitleStore();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // const handleButtonClick = async () => {
  //   setFinalFlag(true);
  //   setActiveStep(activeStep + 1);
  // };

  useEffect(() => {
    updateTitle('Solar Plant Recommendation | VaySolar');
    setSubmitIsClicked(true);
  }, [setSubmitIsClicked, updateTitle]);

  const recommendation = useRecommendation(useFormInputs);
  return (
    <Container>
      <Stack pb={15} alignItems={'center'}>
        <SolarPlantRecommendation recommendation={recommendation} />
        {/* <Button
          variant='contained'
          size={isMobile ? 'small' : 'medium'}
          disableElevation
          startIcon={<Sell />}
          onClick={handleButtonClick}
        >
          {t('main.plantRecommendation.requestQuotes')}
        </Button> */}
      </Stack>
    </Container>
  );
};
export default PlantRecommendation;

const useRecommendation = (useFormInputs) => {
  const { t } = useTranslation();
  let recommendation = {};
  const { siteVisitData, propertyDetails } = useFormInputs;
  if (siteVisitData.powerCutFrequency === PowerCutFrequency.FREQUENT)
    recommendation.plantType = t(
      'main.solarPlantRecommendation.recommendation.plantType.offGrid',
    );
  else
    recommendation.plantType = t(
      'main.solarPlantRecommendation.recommendation.plantType.onGrid',
    );
  if (
    (siteVisitData.roofUsage === RoofUsageFrequency.RARE ||
      siteVisitData.roofUsage === RoofUsageFrequency.FREQUENT) &&
    siteVisitData.roofType === t('main.roofType.options.opt1')
  )
    recommendation.structure = t(
      'main.solarPlantRecommendation.recommendation.plantStructure.overhead',
    );
  else
    recommendation.structure = t(
      'main.solarPlantRecommendation.recommendation.plantStructure.roofMounted',
    );
  recommendation.load =
    siteVisitData.recommendedLoad || propertyDetails.sanctionedLoad;

  return recommendation;
};
