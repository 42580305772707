import { Person } from '@mui/icons-material';
import {
  Container,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { addCustomerDetails, getTokens } from '../../services/CustomerService';
import {
  newCustomerRegistrationEventSms,
  newCustomerRegistrationEventWhatsapp,
} from '../../services/EventService';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import { usePageTitleStore } from '../../store/PageTitleStore';

const FullName = () => {
  const { updateTitle } = usePageTitleStore();
  const {
    useFormInputs,
    updateFormInputs,
    setActiveStep,
    setFinalFlag,
    submitIsClicked,
    setSubmitIsClicked,
  } = useCustomerStore();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const validationSchema = yup.object({
    fullName: yup.string().required(t('main.contactYou.input.errorFullName')),
  });

  const formik = useFormik({
    initialValues: {
      fullName: useFormInputs['fullName'] || '',
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      const updatedFormInputs = {
        ...useFormInputs,
        fullName: values.fullName,
        propertyDetails: {
          ...useFormInputs['propertyDetails'],
        },
      };
      updateFormInputs(updatedFormInputs);
      // setActiveStep(activeStep + 1);

      try {
        const details = await addCustomerDetails(updatedFormInputs);
        await newCustomerRegistrationEventWhatsapp(details.id);
        await newCustomerRegistrationEventSms(details.id);
        const tokens = await getTokens(details);
        if (tokens) {
          localStorage.setItem('accessToken', tokens.accessToken);
          localStorage.setItem('refreshToken', tokens.refreshToken);
        }

        updateFormInputs({
          ...updatedFormInputs,
          id: details.id,
          propertyDetails: {
            ...updatedFormInputs.propertyDetails,
            id: details.propertyDetails[0].id,
          },
        });
        navigate('/site-details');
        setFinalFlag(false);
        setSubmitIsClicked(false);
        setActiveStep(0);
        // setFinalFlag(true);
        // setActiveStep(activeStep + 1);
      } catch (error) {}
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    updateTitle('Contact Details | VaySolar');
  }, [updateTitle]);

  useEffect(() => {
    if (submitIsClicked) {
      formik.handleSubmit();
      if (formik.errors.fullName) {
        formik.setFieldError('fullName', formik.errors.fullName);
      }
      setSubmitIsClicked(false);
    }
  }, [formik, submitIsClicked]);

  return (
    <Container>
      <Stack spacing={1} alignItems={isMobile ? 'flex-start' : 'center'}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
              md: theme.typography.h4.fontSize,
            },
            fontWeight: 'bold',
          }}
        >
          {t('main.contactYou.title2.text1')}{' '}
          <span style={{ color: theme.palette.golden }}>
            {t('main.contactYou.title2.text2')}
          </span>{' '}
          {t('main.contactYou.title2.text3')}{' '}
        </Typography>

        <Stack spacing={4} width='100%' maxWidth={'600px'}>
          <Stack>
            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              marginTop={10}
            >
              <span
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                <Person />
              </span>
              <TextField
                label={t('main.contactYou.input.fullName')}
                variant='standard'
                name='fullName'
                color='success'
                fullWidth
                value={formik.values.fullName}
                onChange={formik.handleChange}
                helperText={t('main.contactYou.input.subtitle')}
              />
            </Stack>
            {formik.touched.fullName && formik.errors.fullName ? (
              <Typography
                sx={{ color: theme.palette.error.main, marginLeft: '2rem' }}
              >
                {formik.errors.fullName}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default FullName;
