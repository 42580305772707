import {
  Box,
  Collapse,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getProjectEstimate } from '../../services/CustomerService';
import { useCustomerStore } from '../../store/CustomerJourneyStore';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const TabularBenefits = () => {
  const theme = useTheme();
  const { useFormInputs, updateFormInputs } = useCustomerStore();
  const [dataField, setDataField] = useState('');
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const onMount = async () => {
      try {
        const region = useFormInputs.propertyDetails.address?.state || 'Delhi';
        const sanctionedLoad =
          useFormInputs.propertyDetails.sanctionedLoad || 3;
        const propertyType =
          useFormInputs.propertyDetails.propertyType || 'RESIDENTIAL';
        const billAmount = useFormInputs.propertyDetails.billAmount || 2000;
        const billMonth = useFormInputs.propertyDetails.billMonth || 'JANUARY';
        const shadowFreeArea =
          useFormInputs.propertyDetails.shadowFreeArea || 0;
        const data = await getProjectEstimate(
          region,
          propertyType,
          sanctionedLoad,
          billAmount,
          shadowFreeArea,
          billMonth,
        );
        setChecked(true);
        if (data.length === 0) throw new Error('No data found');
        const transformedData = data.output;
        setDataField(transformedData);

        updateFormInputs({
          ...useFormInputs,
          siteVisitData: {
            ...useFormInputs.siteVisitData,
            recommendedLoad: transformedData.plantSize,
            numberOfSolarInstallers: transformedData.numberOfSolarInstallers,
            region: transformedData.region,
          },
        });
      } catch (error) {
        toast.error(error);
      }
    };
    onMount();
  }, []);
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingInline: '0px !important',
      }}
    >
      <Stack alignItems='center'>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
              md: theme.typography.h4.fontSize,
            },
            fontWeight: 'bold',
            pr: { md: '0px', xs: '16px' },
          }}
        >
          {t('main.benefitPage.electricityProfile')}{' '}
          <span style={{ color: theme.palette.primary.main }}>
            {dataField.plantSize || '..'} {t('main.benefitPage.solar')}
          </span>
        </Typography>
        <Box height='500px'>
          <Collapse in={checked} timeout={4000} collapsedSize={0}>
            <Box maxWidth={'700px'} pt={{ md: '50px', xs: '38px' }}>
              <Grid container>
                <Grid item xs={5} alignSelf={'end'}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                    }}
                    component={'p'}
                  >
                    {t('main.benefitPage.costYouPay')}
                  </Typography>
                </Grid>
                <Grid item xs={7} alignSelf={'end'}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.h6.fontSize,
                        md: theme.typography.h5.fontSize,
                      },
                    }}
                    fontWeight={'bold'}
                    component={'p'}
                    textAlign={'right'}
                  >
                    ₹ {dataField.installationCostRange || '..'}
                  </Typography>
                </Grid>
                <Grid item xs={12} pb={{ md: '18px', xs: '12px' }} pt='0px'>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.caption.fontSize,
                        md: theme.typography.subtitle2.fontSize,
                      },
                      color: 'text.secondary',
                    }}
                    component={'p'}
                    pb={'22px'}
                  >
                    {t('main.benefitPage.emiAvailable')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  pb={{ md: '8px', xs: '6px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                    }}
                    component={'p'}
                  >
                    {t('main.benefitPage.centralSubsidy')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  pb={{ md: '8px', xs: '6px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    component={'p'}
                    sx={{
                      textAlign: 'right',
                      fontSize: {
                        xs: theme.typography.subtitle1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },

                      fontWeight: 'bold',
                    }}
                    color='primary'
                  >
                    - ₹ {dataField.centralGovtSubsidy || '..'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  pb={{ md: '8px', xs: '6px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                    }}
                    component={'p'}
                  >
                    {t('main.benefitPage.stateSubsidy')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  pb={{ md: '8px', xs: '6px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    component={'p'}
                    color='primary'
                    sx={{
                      textAlign: 'right',
                      fontSize: {
                        xs: theme.typography.subtitle1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },

                      fontWeight: 'bold',
                    }}
                  >
                    - ₹ {dataField.stateGovtSubsidy || '..'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  pt={{ md: '24px', xs: '20px' }}
                  pb={{ md: '16px', xs: '14px' }}
                >
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={5}
                  pb={{ md: '6px', xs: '4px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                    }}
                    component={'p'}
                  >
                    {t('main.benefitPage.netCost')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  pb={{ md: '6px', xs: '4px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    component={'p'}
                    color='primary'
                    sx={{
                      textAlign: 'right',
                      fontSize: {
                        xs: theme.typography.subtitle1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },

                      fontWeight: 'bold',
                    }}
                  >
                    ₹ {dataField.netCostRange || '..'}
                  </Typography>
                </Grid>
                <Grid item xs={8} alignSelf={'flex-end'}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                    }}
                    component={'p'}
                  >
                    {t('main.benefitPage.solarElectricityCost')}
                  </Typography>
                </Grid>
                <Grid item xs={4} alignSelf={'flex-end'}>
                  <Typography
                    component={'p'}
                    color='primary'
                    sx={{
                      textAlign: 'right',
                      fontSize: {
                        xs: theme.typography.subtitle1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },

                      fontWeight: 'bold',
                    }}
                  >
                    ₹{' '}
                    {(
                      dataField.min_cost / dataField.lifeTimeProduction
                    ).toFixed(1) || '..'}
                    /kWh
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  pb={{ md: '24px', xs: '16px' }}
                  pt={{ md: '4px', xs: '2px' }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.caption.fontSize,
                        md: theme.typography.subtitle2.fontSize,
                      },
                      color: 'text.secondary',
                    }}
                    component={'p'}
                    pb={'22px'}
                  >
                    {t('main.benefitPage.lifetimeProduction')}{' '}
                    {dataField.lifeTimeProductionRounded || '..'} units
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10.5}
                  pb={{ md: '6px', xs: '4px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                    }}
                    component={'p'}
                  >
                    {t('main.benefitPage.billReduction')}{' '}
                    <Typography
                      component='span'
                      sx={{
                        fontSize: {
                          xs: theme.typography.caption.fontSize,
                          md: theme.typography.subtitle2.fontSize,
                        },
                        color: 'text.secondary',
                      }}
                    >
                      (from ₹{' '}
                      {Math.round(dataField.firstYearBillsWithoutSolar / 100) *
                        100}{' '}
                      to ₹{' '}
                      {Math.round(dataField.firstYearBillsWithSolar / 100) *
                        100}{' '}
                      )
                    </Typography>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  pb={{ md: '6px', xs: '4px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    component={'p'}
                    color='primary'
                    sx={{
                      textAlign: 'right',
                      fontSize: {
                        xs: theme.typography.subtitle1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },

                      fontWeight: 'bold',
                    }}
                  >
                    {dataField.billReduction || '..'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  pb={{ md: '6px', xs: '4px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                    }}
                    component={'p'}
                  >
                    {t('main.benefitPage.firstYearSavings')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  pb={{ md: '6px', xs: '4px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    component={'p'}
                    color='primary'
                    sx={{
                      textAlign: 'right',
                      fontSize: {
                        xs: theme.typography.subtitle1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },

                      fontWeight: 'bold',
                    }}
                  >
                    ₹ {dataField.firstYearSavings || '..'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  pb={{ md: '6px', xs: '4px' }}
                  alignSelf={'flex-end'}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                    }}
                    component={'p'}
                  >
                    {t('main.benefitPage.lifetimeSavings')}
                  </Typography>
                </Grid>
                <Grid item xs={5} pb={{ md: '6px', xs: '4px' }}>
                  <Typography
                    component={'p'}
                    color='primary'
                    sx={{
                      textAlign: 'right',
                      fontSize: {
                        xs: theme.typography.subtitle1.fontSize,
                        md: theme.typography.h6.fontSize,
                      },

                      fontWeight: 'bold',
                    }}
                  >
                    ₹ {dataField.lifeTimeSavings || '..'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </Box>
      </Stack>
    </Container>
  );
};

export default TabularBenefits;
