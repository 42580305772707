import { Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { toSentenceCase } from '../../utils/utils';

const PropertyDetails = ({
  propertyDetails = {},
  siteVisitData = {},
  textVariant = 'tertiary',
  overrideAlignment,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const propertyTypeText = propertyDetails.propertyType ? (
    <>
      <strong>{propertyDetails.propertyType.toLowerCase()}</strong>
      {' ' + t('main.propertyDetails.property')}
    </>
  ) : null;

  const billAmountText = propertyDetails.billAmount ? (
    <>
      {t('main.propertyDetails.withBill') + ' ₹ '}{' '}
      {
        <strong>
          {propertyDetails.billAmount.toLocaleString('en-IN')}{' '}
          {t('main.propertyDetails.inLastMonth')}
        </strong>
      }{' '}
      {<br />} {t('main.propertyDetails.approxAnnualBill')}{' '}
      <strong>
        {' ₹ '}
        {(propertyDetails.billAmount * 12).toLocaleString('en-IN')}
      </strong>
    </>
  ) : null;

  const roofTypeText = siteVisitData.roofType ? (
    <>
      <strong>{siteVisitData.roofType.toLowerCase()}</strong>
      {' ' + t('main.propertyDetails.roof')}
    </>
  ) : null;

  const floorText = siteVisitData.floor ? (
    <>
      {t('main.propertyDetails.on')}
      <strong>
        {' floor '} {siteVisitData.floor}
      </strong>{' '}
      {t('main.propertyDetails.roofWith')} {siteVisitData.roofTopArea}{' '}
      <strong>{t('main.propertyDetails.sqFt')} </strong>
      {t('main.propertyDetails.area')}
    </>
  ) : null;

  const powerCutFrequencyText = siteVisitData.powerCutFrequency ? (
    <>
      <strong>{siteVisitData.powerCutFrequency.toLowerCase()}</strong>{' '}
      {t('main.propertyDetails.powerCuts')}
    </>
  ) : null;

  const roofUsageText = siteVisitData.roofUsage ? (
    <>
      <strong>{siteVisitData.roofUsage.toLowerCase()}</strong>{' '}
      {t('main.propertyDetails.roofUsage')}
    </>
  ) : null;

  return (
    <Stack
      alignItems={
        overrideAlignment
          ? overrideAlignment
          : isMobile
            ? 'flex-start'
            : 'center'
      }
    >
      <Typography
        sx={{
          fontSize: isMobile
            ? theme.typography.subtitle2.fontSize
            : theme.typography.subtitle2.fontSize,
          textAlign:
            overrideAlignment === 'start'
              ? 'start'
              : isMobile
                ? 'start'
                : 'center',
          color:
            textVariant === 'primary'
              ? theme.palette.text.primary
              : theme.palette.text.tertiary,
        }}
      >
        {propertyTypeText} {propertyTypeText && <br />}
        {billAmountText}{' '}
        {billAmountText && (
          <>
            <br />
            <br />
          </>
        )}
        {roofTypeText} {roofTypeText && <br />}
        {floorText} {floorText && <br />}
        {powerCutFrequencyText} {powerCutFrequencyText && <br />}
        {roofUsageText}
      </Typography>
    </Stack>
  );
};

export default PropertyDetails;
