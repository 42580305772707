import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import hero1 from '../../Image/hero1.svg';
import Logo from '../Logo';
import { fadeAnimateVariants, slideAnimateVariants } from '../animate/animate';

const TopSec = ({ startLink, researchLink }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      sx={{
        minHeight: '90vh',
      }}
    >
      <Logo height={'80px'} width={'156px'} />
      <Grid
        pt={{ sm: '32px', xs: '10px' }}
        pb={'32px'}
        container
        spacing={'28px'}
      >
        <Grid item xs={12}>
          <Typography
            custom={0.5}
            variant='h2'
            variants={fadeAnimateVariants}
            initial='initial'
            whileInView='animate'
            viewport={{
              once: true,
            }}
            component={motion.h2}
            fontWeight={'bold'}
            fontSize={{
              md: '48px',
              sm: '40px',
              xs: '34px',
            }}
          >
            Going{' '}
            <Box
              component='span'
              sx={{
                color: 'primary.main',
              }}
            >
              solar
            </Box>{' '}
            was never this easy!
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction={'row-reverse'}
          sx={{
            spacing: { sm: '10px', xs: '40px' },
          }}
        >
          <Grid
            item
            sm={5}
            xs={12}
            sx={{
              position: 'relative',
              pt: { xs: '0px', sm: '25px' },
            }}
          >
            <Box
              className='center green pulse'
              variants={fadeAnimateVariants}
              initial='initial'
              custom={2.5}
              whileInView='animate'
              viewport={{
                once: true,
              }}
              component={motion.div}
              sx={{
                width: { xl: '52px', md: '45px', sm: '40px', xs: '28px' },
                height: { xl: '52px', md: '45px', sm: '40px', xs: '28px' },
                borderRadius: '50%',
                boxShadow: '0px 0px 1px 1px #FBD300',
                backgroundColor: '#FBD300',
                animation: 'pulse-animation 2s infinite',
                position: 'absolute',
                left: { sm: 0, xs: '200px' },
                top: 0,
                zIndex: 1,
                '@keyframes pulse-animation': {
                  '0%': {
                    boxShadow: '0 0 0 0px #FBD300',
                  },
                  '100%': {
                    boxShadow: '0 0 0 12px rgba(0, 0, 0, 0)',
                  },
                },
              }}
            >
              {/* Your component content */}
            </Box>
            <Box
              component={motion.img}
              variants={fadeAnimateVariants}
              initial='initial'
              whileInView='animate'
              sx={{
                transform: { sm: 'scaleX(1)', xs: 'scaleX(-1)' },
              }}
              custom={2.5}
              viewport={{
                once: true,
              }}
              width='100%'
              maxWidth={{
                md: 380,
                sm: 350,
                xs: 350,
              }}
              height='100%'
              src={hero1}
              alt=''
            />
          </Grid>
          <Grid item sm={7} xs={12} alignSelf={'flex-end'}>
            <Typography
              variant='h3'
              component={motion.h3}
              variants={fadeAnimateVariants}
              initial='initial'
              whileInView='animate'
              custom={1.5}
              viewport={{
                once: true,
              }}
              fontSize={{
                md: '32px',
                sm: '28px',
                xs: '20px',
              }}
              marginTop='40px'
              fontWeight={'bold'}
            >
              <Box
                component='span'
                sx={{
                  color: 'primary.main',
                }}
              >
                Find
              </Box>{' '}
              trusted{' '}
              <Box
                component='span'
                sx={{
                  color: 'primary.main',
                }}
              >
                nearby solar installers
              </Box>
            </Typography>
            {/*Buttons*/}
            <Stack
              spacing='15px'
              direction='row'
              pt={{ md: '28px', xs: '20px' }}
            >
              <Button
                variant='contained'
                size={isMobile ? 'medium' : 'large'}
                component={motion.button}
                variants={slideAnimateVariants}
                initial='initial'
                whileInView='animate'
                custom={3.5}
                viewport={{
                  once: true,
                }}
                disableElevation
                sx={{
                  letterSpacing: '1.3px',
                }}
                endIcon={<ArrowCircleRightIcon />}
                onClick={() => navigate(startLink)}
              >
                Start
              </Button>
              <Button
                onClick={() => {
                  if (researchLink) {
                    navigate(researchLink);
                  } else {
                    window.location.href =
                      'https://www.vaysolar.com/docs/?utm_source=landing-page&utm_medium=landing-page&utm_campaign=landing-page';
                  }
                }}
                size={isMobile ? 'medium' : 'large'}
                variant='text'
                sx={{
                  letterSpacing: '0.7px',
                }}
                component={motion.button}
                variants={slideAnimateVariants}
                initial='initial'
                whileInView='animate'
                custom={4.5}
                viewport={{
                  once: true,
                }}
                startIcon={<EnergySavingsLeafOutlinedIcon />}
              >
                Research
              </Button>
            </Stack>
            <Button
              variant='text'
              component={motion.button}
              variants={slideAnimateVariants}
              initial='initial'
              whileInView='animate'
              custom={5.5}
              viewport={{
                once: true,
              }}
              sx={{
                mt: 1,
                textTransform: 'capitalize',
                color: theme.palette.text.primary,
              }}
              onClick={() => navigate('/login')}
            >
              Already registered. Sign In
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default TopSec;
