import HelpIcon from '@mui/icons-material/Help';
import { Box, Container, IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import LanguageMenu from '../components/LanguageMenu';
import Logo from '../components/Logo';
import { usePageTitleStore } from '../store/PageTitleStore';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import { renderToStaticMarkup } from 'react-dom/server';
import SEO from './SEO';

const JourneyLayout = ({ children }) => {
  const { useTitle } = usePageTitleStore();
  const theme = useTheme();

  const iconSvgString3 = renderToStaticMarkup(
    <svg xmlns='http://www.w3.org/2000/svg' fill='#1B833312'>
      <OfflineBoltIcon
        fontSize='inherit'
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    </svg>,
  );

  return (
    <>
      <SEO
        title={useTitle}
        description='VaySolar is a platform helping property owners find trusted solar installers. '
        name='VaySolar'
        type='article'
      />
      <Box sx={{ maxWidth: '1024px', mx: 'auto' }}>
        <Container sx={{ paddingInline: '0px !important' }}>
          <Stack
            sx={{
              background: {
                xs: `url(${`data:image/svg+xml,${encodeURIComponent(iconSvgString3)}`})`,
                sm: 'none',
              },
              backgroundRepeat: 'no-repeat',
              backgroundSize: '70%',
              backgroundPosition: 'right top',
              minHeight: '100vh',
            }}
          >
            <Stack
              direction={'row'}
              position={'relative'}
              justifyContent={{ sm: 'center', xs: 'space-between' }}
              alignItems={'center'}
              pb={3}
            >
              <Logo height={'80px'} width={'156px'} />
              <Box
                mr={{ sm: 2, xs: 1 }}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: { sm: 'absolute' },
                  right: 0,
                }}
              >
                <LanguageMenu />
                <a
                  href='https://forms.gle/FYBfYapmfg77pZkz9'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconButton
                    size='small'
                    sx={{ color: theme.palette.primary.main }}
                  >
                    <HelpIcon />
                  </IconButton>
                </a>
              </Box>
            </Stack>
            {children}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default JourneyLayout;
