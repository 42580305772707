import { useTheme } from '@emotion/react';
import { Box, Slide, Stack, Typography } from '@mui/material';
import React from 'react';

const SearchOnAnimation = ({ isSearchOver, propertyDetails }) => {
  const theme = useTheme();

  return (
    <Slide
      in={!isSearchOver}
      timeout={{ enter: 500, exit: 800 }}
      unmountOnExit
      mountOnEnter
    >
      <Stack
        width='100vw'
        alignItems={'center'}
        height='100vh'
        bgcolor={'#A8D49957'}
      >
        <Box
          pt={{ md: '10%', xs: '20%' }}
          pb={{ md: '20%', xs: '45%' }}
          position={'relative'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box
            position={'absolute'}
            width='100px'
            height='100px'
            bgcolor={'#FBC400'}
            borderRadius={'50%'}
            left={{ sm: -50, xs: -30 }}
            sx={{
              position: 'absolute',
              width: '80px',
              height: '80px',
              bgcolor: '#FBC400',
              borderRadius: '50%',
              animation: 'pulse-animation 2s infinite',
              '@keyframes pulse-animation': {
                '0%': {
                  boxShadow: `0 0 0 0px #FBC400`,
                },
                '100%': {
                  boxShadow: '0 0 0 18px rgba(0, 0, 0, 0)',
                },
              },
            }}
          />
          <Typography variant='h5' fontWeight={'bold'} position={'relative'}>
            Your solar search is active
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h6.fontSize,
              sm: theme.typography.h5.fontSize,
            },
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            borderRight: '.1em solid #000',
            animation:
              'typing 4s steps(5, end) infinite, blink-caret .75s step-end infinite',
            '@keyframes typing': {
              '0%': { width: '17ch' },
              '50%': { width: '19ch' },
              '100%': { width: '17ch' },
            },
            '@keyframes blink-caret': {
              'from, to': { borderColor: 'transparent' },
              '50%': { borderColor: '#000' },
            },
          }}
        >
          Locating trusted installers.....
        </Typography>
        <Typography
          sx={{
            pt: '5px',
            fontSize: {
              xs: theme.typography.body2.fontSize,
              sm: theme.typography.body1.fontSize,
            },
          }}
        >
          {propertyDetails?.sanctionedLoad} kW solar plant
          {propertyDetails?.siteVisitData?.region &&
            ` in ${propertyDetails.siteVisitData.region}`}
        </Typography>
      </Stack>
    </Slide>
  );
};

export default SearchOnAnimation;
