import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Link,
} from '@mui/material';
import { useEffect, useState } from 'react';
import DashboardStepper from '../components/dashboard/DashboardStepper';
import PropertyDetails from '../components/ui/PropertyDetails';
import { DashboardLayout } from '../layout/DashboardLayout';
import {
  getProposalsByUserId,
  getUserProperties,
  getVendorList,
} from '../services/CustomerService';
import { usePageTitleStore } from '../store/PageTitleStore';
import { useSearchAnimate } from '../store/SearchAnimateStore';

const DashboardPage = () => {
  const { updateTitle, updateDescription } = usePageTitleStore();
  const [vendorData, setVendorData] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [proposals, setProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [installationTimeline, setInstallationTimeline] = useState('1 month');
  const { setIsSearchOver, setIsLooking } = useSearchAnimate();

  useEffect(() => {
    updateTitle('Dashboard | VaySolar');
    updateDescription(
      'VaySolar a platform for vendor and customer to unite world with solar power energy',
    );
  }, [updateDescription, updateTitle]);

  useEffect(() => {
    const onMount = async () => {
      try {
        const data = await getUserProperties();
        setPropertyDetails(data[0]);
        setInstallationTimeline(data[0]?.installationTimeline);
        if (data[0]?.installationTimeline !== 'Not looking') {
          setIsSearchOver(false);
          setIsLooking(true);
        }
        const proposalsResponse = await getProposalsByUserId();
        setProposals(proposalsResponse);
        const vendorListResponse = await getVendorList();
        setVendorData(vendorListResponse);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    onMount();
  }, []);

  if (isLoading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
        sx={{
          flexGrow: 1,
          px: { sm: 3, xs: 1.5 },
          py: { sm: 3, xs: 2 },
          pb: { sm: 3, xs: 8 },
        }}
      >
        <CircularProgress color='primary' />
      </Box>
    );
  }

  return (
    <DashboardLayout
      announcmentBar={true}
      propertyDetails={propertyDetails}
      installationTimeline={installationTimeline}
    >
      <Container
        component='main'
        sx={{
          flexGrow: 1,
          pb: 2,
          paddingInline: { md: '24px', xs: '12px' },
          paddingTop: '13px',
        }}
      >
        <Breadcrumbs
          aria-label='breadcrumb'
          sx={{
            pb: 2,
          }}
        >
          <Link underline='hover' variant='subtitle2' color='inherit' href='#'>
            Account
          </Link>
          <Link underline='hover' variant='subtitle2' href='#' color={'black'}>
            {propertyDetails?.sanctionedLoad} kW load
            {propertyDetails?.siteVisitData?.roofTopArea &&
              `, ${propertyDetails.siteVisitData.roofTopArea} sq ft`}
            {propertyDetails?.siteVisitData?.region &&
              ` @ ${propertyDetails.siteVisitData.region}`}
          </Link>
        </Breadcrumbs>
        <PropertyDetails
          textVariant='primary'
          overrideAlignment='start'
          propertyDetails={propertyDetails}
        />
        <DashboardStepper
          proposals={proposals}
          propertyDetails={propertyDetails}
          vendorData={vendorData}
        />
      </Container>
    </DashboardLayout>
  );
};
export default DashboardPage;
